import React from "react";
import styled from "styled-components";

import { grey200 } from "ui/colors";
import { appFontSize } from "uiKit/theme/fonts";

export default function SuggestionList({ children }) {
    return <Root>{children}</Root>;
}

const Root = styled.div`
    width: auto;

    position: absolute;
    overflow-y: auto;
    z-index: 9999;

    background-color: white;
    border: 2px solid ${grey200};
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);

    font-size: ${appFontSize};

    // Override the list items.
    li {
        display: block;
        white-space: nowrap;
        padding: 0.75em;

        // This is the distinction between the selected and
        // not selected items.
        &[aria-selected="true"] {
            background-color: ${grey200};
        }
    }
`;
