import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import track from "track";
import Rollbar from "rollbar";

import {
    IconExitFullscreen,
    IconFullscreen,
    IconMinus,
    IconPlus,
} from "ui/icons";
import { SecondaryButton, UIButtonStrip } from "ui";
import { spacing100, spacing50 } from "ui/spacing";

const ZOOM_STEP = 0.25;
const ZOOM_LOWER_LIMIT = 0.25;
const ZOOM_UPPER_LIMIT = 4.0;

interface IProps {
    /**
     * Supports a single child component containing interactive custom diagram iframes,
     * static custom diagram images or plain image elements.
     * @type React.ReactElement
     */
    children: React.ReactElement;

    /**
     * Sets the Segment Track metadata for identifying where the component is being used.
     * @type string
     */
    trackingProperties: {
        location: string;
        reference_id: string | null;
    };
}

/**
 *  ```js
 * import { ZoomFullscreen } from "ui";
 * ```
 * The **ZoomFullscreen** component attaches zoom and fullscreen controls to the single child
 * component that it contains.
 */

export default function ZoomFullscreen(props: IProps) {
    const [zoomFactor, setZoomFactor] = useState(1);
    // Factor to scale content to fit height of screen when in fullscreen mode (see below)
    const [fullScreenScaleModifier, setFullscreenScaleModifier] = useState(1);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const rootElRef = useRef<HTMLDivElement>(null);
    const transformElRef = useRef<HTMLDivElement>(null);

    async function toggleFullScreen() {
        if (rootElRef.current) {
            try {
                if (!isFullScreen) {
                    // Before we trigger requestFullscreen(), what we need to know is whether
                    // the content entering full screen mode would end up being taller than the screen.
                    // If it is, we will need to scale it down so that it fits the screen nicely.
                    const { width, height } =
                        transformElRef.current!.getBoundingClientRect();
                    const contentAspectRatio = width / height;
                    const screenAspectRatio =
                        window.screen.width / window.screen.height;

                    // Assumption 1: when a thing is wider than it is tall, the aspect ratio
                    // would be greater than 1 e.g 16:9 (16/9 = 1.77) or 4:3 (4/3 = 1.33).
                    //
                    // Assumption 2: when a thing is taller than it is wide, the aspect ratio
                    // would be less than 1 e.g 400px x 800px (400/800 = 0.5).
                    //
                    // Assumption 3: a browser's default behaviour is that it scales its contents
                    // by width. If we are trying to scale one thing (e.g content) within the width
                    // AND height of another thing (e.g screen), we divide the aspect ratios of
                    // the two things, where the numerator is the fixed dimension (i.e screen)
                    // and denominator is the thing being scaled (i.e content)
                    //
                    // If the resulting ratio between the screen's aspect ratio and the content's
                    // aspect ratio is less than or equal to 1, the content will always fit inside
                    // the screen's height.
                    //
                    // (16:9 screen - 1920x1080px) / (2:1 content - 192x96px) = .889
                    //
                    // So if we were to scale image to be 10x wider to fit the screen, it will
                    // only be 960px high and fit in the screen.
                    //
                    // If this ratio is greater than 1, then we know that the content will not fit
                    // in the screen and we will need to scale by its height - rather than relying
                    // on the browser behaviour of default scaling by width.
                    //
                    // (16:9 screen - 1920x1080px) / (1:2 content - 192x384px) = 3.556
                    //
                    // Given the above assumptions, we scale by height only if necessary e.g the
                    // screen's aspect ratio is greater than the content's aspect ratio.
                    if (screenAspectRatio > contentAspectRatio) {
                        // In order to scale by height, we first calculate the ratio between the
                        // content's current width to the screen's full width. Then we multiply
                        // the content's height by the same factor to get the new overall dimensions.
                        const widthFactor = window.innerWidth / width;
                        const heightFactor = height * widthFactor;

                        // Finally, we re-scale the content down by this factor so that it
                        // fits within the height of the screen.
                        setFullscreenScaleModifier(
                            window.outerHeight / heightFactor,
                        );
                    }

                    await rootElRef.current.requestFullscreen();
                    setIsFullScreen(true);
                    track("Fullscreen Opened", {
                        ...props.trackingProperties,
                    });
                } else {
                    await document.exitFullscreen();
                }
            } catch (e) {
                // @ts-ignore Rollbar typings are broken
                Rollbar.error("Failed to toggle fullscreen mode", e);
            }
        }
    }

    useEffect(() => {
        if (!rootElRef.current) return;

        const rootEl = rootElRef.current;
        function fullscreenChange() {
            if (!document.fullscreenElement) {
                track("Fullscreen Closed", { ...props.trackingProperties });

                setIsFullScreen(false);

                // When we exit full screen, we can reset the scale modifier to 1 so that it will
                // go back to being scaled by width e.g browser default behaviour.
                setFullscreenScaleModifier(1);
            }
        }

        rootEl.addEventListener("fullscreenchange", fullscreenChange);

        return () => {
            rootEl.removeEventListener("fullscreenchange", fullscreenChange);
        };
    });

    return (
        <Root ref={rootElRef} isFullScreen={isFullScreen}>
            <OverflowContainer>
                <TransformElement
                    ref={transformElRef}
                    style={{
                        transform: `scale(${zoomFactor * fullScreenScaleModifier})`,
                    }}
                    zoomFactor={zoomFactor}
                >
                    {props.children}
                </TransformElement>
            </OverflowContainer>
            <Controls>
                <UIButtonStrip>
                    <SecondaryButton
                        onClick={() => {
                            setZoomFactor(zoomFactor - ZOOM_STEP);
                            track("Zoom Clicked", {
                                ...props.trackingProperties,
                                value: `${(zoomFactor - ZOOM_STEP) * 100}%`,
                                context: "Zoom out",
                            });
                        }}
                        disabled={zoomFactor === ZOOM_LOWER_LIMIT}
                    >
                        <IconMinus />
                    </SecondaryButton>
                    <SecondaryButton
                        onClick={() => {
                            setZoomFactor(1);
                            track("Zoom Clicked", {
                                ...props.trackingProperties,
                                value: "100%",
                                context: "Reset",
                            });
                        }}
                    >
                        <span>{zoomFactor * 100}%</span>
                    </SecondaryButton>
                    <SecondaryButton
                        onClick={() => {
                            setZoomFactor(zoomFactor + ZOOM_STEP);
                            track("Zoom Clicked", {
                                ...props.trackingProperties,
                                value: `${(zoomFactor + ZOOM_STEP) * 100}%`,
                                context: "Zoom in",
                            });
                        }}
                        disabled={zoomFactor === ZOOM_UPPER_LIMIT}
                    >
                        <IconPlus />
                    </SecondaryButton>
                </UIButtonStrip>
                <SecondaryButton
                    onClick={() => {
                        toggleFullScreen();
                    }}
                >
                    {isFullScreen ? <IconExitFullscreen /> : <IconFullscreen />}
                </SecondaryButton>
            </Controls>
        </Root>
    );
}

const Root = styled.div<{ isFullScreen: boolean }>`
    // Using flex and column here so that the space between the content and the controls can be maximised
    // whenever there happens to be whitespace.
    display: flex;
    flex-direction: column;

    ${(props) => (props.isFullScreen ? "background-color: white;" : "")}
`;

const OverflowContainer = styled.div`
    overflow: scroll;

    // When in fullscreen mode, maximise the amount of vertical space available for the user to see
    // the content, particularly useful when zoomed in.
    flex: 1;

    // Align center child elements where possible.
    text-align: center;
`;

const TransformElement = styled.div<{
    zoomFactor: number;
}>`
    // Setting transform-origin at center seems to be the best way to center the content when in
    // fullscreen. But note that without transform-origin as top left, zoom functionality via
    // transform scale doesn't let you pan across the child elements with scrollbars as you'd expect,
    // particularly when zoomed in significantly.
    transform-origin: ${(props) =>
        props.zoomFactor > 1 ? "top left" : "top center"};
    transition: all 0.1s ease-in;
`;

const Controls = styled.div`
    display: flex;
    justify-content: space-between;
    margin: ${spacing50} ${spacing100};
`;
