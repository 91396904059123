import React, { useMemo } from "react";
import styled from "styled-components";

import { spacing75, spacing100, spacing50 } from "ui/spacing";
import { grey300, red400 } from "ui/colors";
import { IconFileLines, IconFilePicture, IconTrashCan } from "ui/icons";
import { Text } from "ui";

import FileInput from "./_internal/FileInput";
import DeleteButton from "./_internal/DeleteButton";
import fileDetailsFromDataUri from "./utils/fileDetailsFromDataUri";

interface IDataUriUploaderProps {
    /**
     * Optional accept attribute for the file input.
     * must be a valid file type, eg. `"image/png"`.
     */
    accept?: string;

    /**
     * File name to display, as the value of the upload
     * is a data uri. This is an abitrary name to represent
     * the file.
     *
     * For example, if the file is an icon for a template, you
     * can set this to `"icon"`.
     *
     * @default "file"
     */
    fileNameOverride?: string | null;

    /**
     * The value of the file's DataUri string. We use this for
     * the file type. but perhaps in the future we can use this
     * for preview.
     * @type DataUri<string>
     */
    upload: string | null;

    /**
     * Callback for when the upload is complete. In this case it's a
     * [DataUri](https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URLs)
     * string.
     * @type (value: DataURI<string>) => void
     */
    onUploadComplete: (value: string) => void;

    /**
     * Callback for when the upload is deleted.
     */
    onUploadDelete: () => void;

    /**
     * Boolean to flag the error styling onto the uploader.
     * @default false
     */
    error?: boolean;

    /**
     * Optional error message that will be shown below the uploader.
     * If you're displaying the error message elsewhere nearby, there
     * is no need to add this here as well.
     */
    errorMessage?: string;

    /**
     * Optional boolean to show a preview of the image. Only works
     * if the file is an image.
     * @default false
     */
    showImagePreview?: boolean;
}

export default function DataUriUploader(props: IDataUriUploaderProps) {
    const fileDetails = useMemo(() => {
        if (!props.upload) return;
        return fileDetailsFromDataUri(props.upload);
    }, [props.upload]);

    function onFileChange(file: File | null) {
        if (!file) {
            props.onUploadComplete("");
            return;
        }
        const reader = new FileReader();
        const readFile = (event) => {
            props.onUploadComplete(event.target.result);
        };

        reader.addEventListener("load", readFile);
        reader.readAsDataURL(file);
    }

    if (props.upload) {
        return (
            <>
                {
                    // We can't rely on this right now, because templates that
                    // have been uploaded to the server have a URL here and not
                    // a data uri.
                    //
                    // TODO - We'll want to deal with this in the future.
                    // fileDetails?.classType === "image" &&
                    props.showImagePreview && props.upload && (
                        <ImagePreview
                            src={props.upload}
                            alt={`Preview of ${props.fileNameOverride || "image"}.`}
                        />
                    )
                }
                <FileDisplay>
                    {fileDetails?.classType === "image" ? (
                        <IconFilePicture size={24} />
                    ) : (
                        <IconFileLines size={24} />
                    )}
                    &nbsp;
                    <Text>
                        {props.fileNameOverride || "file"}
                        {fileDetails?.extension || ""}
                    </Text>
                    <DeleteButton onClick={() => props.onUploadDelete()}>
                        <IconTrashCan size={16} color={red400} />
                    </DeleteButton>
                </FileDisplay>
            </>
        );
    }

    return (
        <FileInput
            error={!!props.error}
            errorMessage={props.errorMessage}
            onFileChange={onFileChange}
            accept={props.accept}
        />
    );
}

const FileDisplay = styled.span`
    display: flex;
    align-items: center;
    gap: ${spacing75};

    // Remove the border width to keep the height at a flat
    // 3em.
    padding: calc(${spacing75} - 2px) ${spacing100};

    border: 2px solid ${grey300};
    border-radius: 8px;

    background-color: white;

    > :nth-child(2) {
        flex: 1;
    }
`;

const ImagePreview = styled.img`
    width: 100%;
    border-radius: 3px;
    margin-bottom: ${spacing50};
`;
