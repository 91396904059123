import React from "react";
import styled from "styled-components";

import { grey300 } from "ui/colors";
import { spacing75 } from "ui/spacing";

export interface ICardProps {
    /**
     * Supports any React elements as children. Strings aren't supported,
     * and should be wrapped in [**Text**](/docs/typography-text--docs) or
     * [**Paragraph**](/docs/typography-paragraph--docs) elements.
     * @type Array<React.ReactElement>
     */
    children: React.ReactElement | Array<React.ReactElement>;
}

interface IRootCardProps extends ICardProps {
    /**
     * _Internal UI Use ONLY_ - Used for extending base Card component
     * when building other types of
     */
    className?: string;
}

export default function RootCard(props: IRootCardProps) {
    return <Root className={props.className}>{props.children}</Root>;
}

const Root = styled.div`
    padding: ${spacing75};

    background: white;
    border: 1px solid ${grey300};
    border-radius: 4px;
`;
