import styled from "styled-components";

import { IButtonProps } from "./Button";

interface IProps {
    /**
     * Supports a list of `<UIButton>` based components.
     * @type <UIButton> | <PrimaryButton> | <SecondaryButton>
     */
    children: Array<React.ReactElement<IButtonProps>>;
}

/**
 *  ```js
 * import { UIButtonStrip } from "ui";
 * ```
 *
 * The **UIButtonStrip** is used for rendering UIButton based elements inline.
 *
 * **NOTE** - While there are no hard limits on the number of children that UIButtonStrip can accept,
 * the border styling currently best supports 3 buttons.
 */

export default function UIButtonStrip(props: IProps) {
    return <Root>{props.children}</Root>;
}

const Root = styled.div`
    display: flex;

    > :first-child > span {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    > :not(:first-child):not(:last-child) > span {
        border-radius: 0;
    }

    > :last-child > span {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
`;
