import React from "react";
import { createPortal } from "react-dom";

import styled from "styled-components";

const FloatingBoxRoot = styled.div`
    width: 100%;
    pointer-events: none;
`;
const FloatingBoxWrapper = styled.div`
    position: absolute;
    pointer-events: auto;
`;
const FloatingBoxAnchor = styled.span``;

// type Props = {
//     onClose?: () => void,
//     closeOnEscape: boolean,
//     children?: Children,
// };

let currentFloatingBox;
if (typeof document !== "undefined") {
    document.body.addEventListener(
        "click",
        function (event) {
            if (
                currentFloatingBox &&
                !currentFloatingBox.contains(event.target)
            ) {
                currentFloatingBox.close();
            }
        },
        false,
    );
}

class FloatingBox extends React.Component {
    static defaultProps = {
        closeOnEscape: true,
    };

    anchorEl;
    floatingBoxEl;

    constructor(props) {
        super(props);

        this._onKeyUp = this._onKeyUp.bind(this);
        this._onClick = this._onClick.bind(this);
    }

    componentDidMount() {
        if (currentFloatingBox) {
            currentFloatingBox.close();
        }
        currentFloatingBox = this;

        const { bottom, left, right } = this.anchorEl.getBoundingClientRect();
        const { pageXOffset, pageYOffset } = window;

        this.floatingBoxEl.style.top = `${bottom + pageYOffset}px`;
        this.floatingBoxEl.style.left = `${left + pageXOffset}px`;

        const floatingBoxRect = this.floatingBoxEl.getBoundingClientRect();

        const overflowRight = Math.max(
            floatingBoxRect.right - (window.innerWidth - 10),
            0,
        );
        if (overflowRight > 0) {
            this.floatingBoxEl.style.left = "auto";
            this.floatingBoxEl.style.right = `${window.innerWidth - right}px`;
        }

        if (!this.floatingBoxEl.contains(document.activeElement)) {
            this.floatingBoxEl.focus();
        }
    }

    componentWillUnmount() {
        if (currentFloatingBox === this) {
            currentFloatingBox = null;
        }
    }

    render() {
        return (
            <FloatingBoxAnchor ref={(ref) => (this.anchorEl = ref)}>
                {createPortal(
                    <FloatingBoxRoot
                        onKeyUp={this._onKeyUp}
                        onClick={this._onClick}
                    >
                        <FloatingBoxWrapper
                            className={this.props.className}
                            tabIndex="-1"
                            ref={(ref) => (this.floatingBoxEl = ref)}
                        >
                            {this.props.children}
                        </FloatingBoxWrapper>
                    </FloatingBoxRoot>,
                    document.getElementById("floatingBoxRoot"),
                )}
            </FloatingBoxAnchor>
        );
    }

    _onKeyUp(event) {
        if (event.key === "Escape" && this.props.closeOnEscape) {
            this.close();
        }
    }
    _onClick(event) {
        if (!this.floatingBoxEl.contains(event.target)) {
            this.close();
        }
    }

    close() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }
    contains(node) {
        return this.floatingBoxEl.contains(node);
    }
}
export default FloatingBox;
export { FloatingBoxAnchor };
