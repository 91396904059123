import React from "react";
import styled from "styled-components";
import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";

import { grey400 } from "ui/colors";
import { IconGripVertical } from "ui/icons";

/**
 *  ```js
 * import { DragHandle } from "ui";
 * ```
 * The **DragHandle** component for use with `react-beautiful-dnd` **ONLY**. Accepts the
 * `dragHandleProps` provided by the `<Draggable>` component.
 */
export default function DragHandle(props: DraggableProvidedDragHandleProps) {
    return (
        <Root {...props}>
            <IconGripVertical />
        </Root>
    );
}

const Root = styled.div`
    padding: 1em;
    width: 3em;
    height: 3em;
    color: ${grey400};
`;
