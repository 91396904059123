import React from "react";
import styled from "styled-components";
import { spacing125 } from "ui/spacing";

import { Card, Paragraph, Text } from "ui";
import { grey400 } from "ui/colors";
import { type ICardProps } from "./internal/RootCard";

interface ICardListProps {
    /**
     * Optional message to show if the list is empty.
     */
    emptyText?: string;

    /**
     * Children must be a list of cards.
     * @type Array<Card | TitledCard>
     */
    children: Array<React.ReactElement<ICardProps> | false>;
}

/**
 *  ```js
 * import { CardList } from "ui";
 * ```
 *
 * The **CardList** component is a component for showing a list of cards.
 * It deals with spacing of cards and the empty state.
 */
export default function CardList(props: ICardListProps) {
    return (
        <Root>
            {props.children.length === 0 && props.emptyText && (
                <Card>
                    <Paragraph color={grey400}>
                        <Text>{props.emptyText}</Text>
                    </Paragraph>
                </Card>
            )}
            {props.children}
        </Root>
    );
}

const Root = styled.div`
    > :not(:first-child) {
        margin-top: ${spacing125};
    }
`;
