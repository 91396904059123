import React from "react";
import styled from "styled-components";
import { spacing50 } from "ui/spacing";

interface ISubHeadingProps {
    bold?: boolean;
    children: React.ReactNode;
}
export default function SubHeading(props: ISubHeadingProps) {
    return <Root bold={!!props.bold}>{props.children}</Root>;
}

const Root = styled.h2<{ bold: boolean }>`
    margin: ${spacing50} 0;
    font-size: 1.5em;
    font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
`;
