import React from "react";
import styled from "styled-components";

import { type Color } from "ui/colors";

interface Props {
    /**
     * Text string to be rendered, must be a string of characters only.
     * @type string
     */
    // TODO - We should audit this for just strings.
    children: React.ReactNode;

    /**
     * Make the text bold
     * @default false
     */
    bold?: boolean;

    /**
     * Make the text italic
     * @default false
     */
    italic?: boolean;

    /**
     * Override the default button text color. Must be a supported
     * [**Color**](/docs/foundations-colors--docs).
     * @default currentColor
     * @type Color
     */
    color?: Color;
}

/**
 *  ```js
 * import { Text } from "ui";
 * ```
 *
 * The **Text** component contains a string of characters in the content. This component
 * provides basic styling options for a string of text.
 */
export default function Text(props: Props) {
    return (
        <Root bold={!!props.bold} color={props.color} italic={!!props.italic}>
            {props.children}
        </Root>
    );
}

const Root = styled.span<{ bold?: boolean; color?: Color; italic?: boolean }>`
    font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
    font-style: ${({ italic }) => (italic ? "italic" : "normal")};
    color: ${({ color }) => (color ? color : "currentColor")};
`;
