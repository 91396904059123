import React, { useState, useId } from "react";
import styled, { css } from "styled-components";

import { Text } from "ui";
import { blue400, grey300, red100, red400 } from "ui/colors";
import { spacing100, spacing50, spacing75 } from "ui/spacing";
import { IconTriangleExclamationFilled } from "ui/icons";

export interface IInputProps {
    /**
     * Value for the content of the input.
     */
    value: string | null;

    /**
     * Callback for when the value is changed.
     */
    onValueChange: (value: string) => void;

    /**
     * Optional type for the input
     */
    type?: "email" | "password";

    /**
     * Boolean to flag the error styling onto the input.
     * @default false
     */
    error?: boolean;

    /**
     * Optional error message that will be shown below the input.
     * If you're displaying the error message elsewhere nearby, there
     * is no need to add this here as well.
     */
    errorMessage?: string;

    /**
     * Children are used for the label of the input. Supports
     * Text and Icon components only.
     * @type Text | Icon | Array<Text | Icon>
     */
    children?: React.ReactNode;

    /**
     * Optional ref that can be passed in. It will be attached to the
     * input DOM element, and can be used outside the component to focus
     * the input with the `focus()` function.
     */
    inputRef?: React.Ref<HTMLInputElement>;

    /**
     * Autocomplete field name
     */
    autoComplete?: string;

    /**
     * Disable the input
     * @default false
     */
    disabled?: boolean;

    dataCy?: string;
}

/**
 *  ```js
 * import { Input } from "ui";
 * ```
 *
 * The **Input** component should be used for basic user input. If you need multiline
 * support, you should use the [**TextArea**](/docs/interactive-textarea--docs) component.
 *
 * The `children` of this component represent the label for the Input, if you want to set
 * a value that can be edited, you should use the `value` prop.
 *
 * Your Input will also need to implement the `onValueChange` callback that passes the
 * updated `value` back into the Input, as it a
 * [Controlled Component](https://react.dev/learn/sharing-state-between-components#controlled-and-uncontrolled-components):
 *
 * ```js
 * function ParentComponent() {
 *     const [value, setValue] = useState("");
 *
 *     return <Input
 *         value={value}
 *         onValueChange={setValue}
 *     >
 *         <Text>Enter your value</Text>
 *     </Input>
 * }
 * ```
 */
export default function Input(props: IInputProps) {
    const id = useId();
    const [focussed, setFocussed] = useState(false);

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        props.onValueChange(event.target.value);
    }
    function onFocus(event: React.FocusEvent<HTMLInputElement>) {
        setFocussed(true);
    }
    function onBlur(event: React.FocusEvent<HTMLInputElement>) {
        setFocussed(false);
    }

    return (
        <Root>
            <InputEl
                id={id}
                ref={props.inputRef}
                type={props.type || "text"}
                value={props.value || ""}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                error={!!props.error}
                autoComplete={props.autoComplete}
                data-cy={props.dataCy}
                disabled={!!props.disabled}
            />
            {props.children && (
                <Label
                    htmlFor={id}
                    focussed={focussed}
                    hasValue={!!props.value}
                >
                    {props.children}
                </Label>
            )}
            {props.error && props.errorMessage && (
                <Text color={red400}>
                    <IconTriangleExclamationFilled />
                    &nbsp;{props.errorMessage}
                </Text>
            )}
        </Root>
    );
}

const Root = styled.span`
    display: block;
    width: 100%;
    position: relative;
`;

const InputEl = styled.input<{ error: boolean }>`
    // Remove the border width to keep the height at a flat
    // 3em.
    padding: calc(${spacing75} - 2px);

    border: 2px solid;
    border-radius: 8px;
    border-color: ${(props) => (props.error ? red400 : grey300)};

    background-color: ${(props) => (props.error ? red100 : "white")};

    transition-property: border-color, background-color;
    transition-duration: 0.3s;

    opacity: ${(props) => (props.disabled ? 0.3 : 1)};

    &:focus {
        border-color: ${blue400};

        // Remove the default styling.
        outline: none;
    }
`;

const Label = styled.label<{
    focussed: boolean;
    hasValue: boolean;
}>`
    position: absolute;
    top: 0;
    left: 0;
    padding: ${spacing75};

    // Prevent click for the label so we can focus
    // the input underneath.
    pointer-events: none;

    color: ${grey300};

    transition-property: font-size, padding, transform, color, background-color;
    transition-duration: 0.3s;

    ${(props) =>
        (props.focussed || props.hasValue) &&
        css<any>`
            font-size: 0.75em;
            padding: 0 ${spacing50};
            transform: translateY(-50%) translateX(${spacing100});
            background-color: white;
            color: black;
        `}
`;
