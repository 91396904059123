import React from "react";
import styled from "styled-components";

import { type Color } from "ui/colors";
import { spacing100 } from "ui/spacing";

interface Props {
    /**
     * Supports `<Text>` components only.
     * @type Text
     */
    children: React.ReactNode;

    /**
     * Make the text bold
     * @default false
     */
    bold?: boolean;

    /**
     * Make the text italic
     * @default false
     */
    italic?: boolean;

    /**
     * Override the default button text color. Must be a supported
     * [**Color**](/docs/foundations-colors--docs).
     * @default currentColor
     * @type Color
     */
    color?: Color;

    /**
     * Optional attribute for cypress testing.
     */
    dataCy?: string;
}

/**
 *  ```js
 * import { Paragraph } from "ui";
 * ```
 *
 * The **Paragraph** component is a block of **Text**, that provides some basic
 * styling. Unlike other block elements, the paragraph deals with line height
 * of the text.
 */
export default function Paragraph(props: Props) {
    return (
        <Root
            bold={!!props.bold}
            color={props.color}
            italic={!!props.italic}
            data-cy={props.dataCy}
        >
            {props.children}
        </Root>
    );
}

const Root = styled.p<{ bold?: boolean; color?: Color; italic?: boolean }>`
    font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
    font-style: ${({ italic }) => (italic ? "italic" : "normal")};
    color: ${({ color }) => (color ? color : "currentColor")};

    & + p {
        margin-top: ${spacing100};
    }
`;
