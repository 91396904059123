import React from "react";
import styled, { css } from "styled-components";

import { Button } from "ui";
import { spacing100 } from "ui/spacing";
import { Color, grey100 } from "ui/colors";
import { IButtonProps } from "ui/Button/Button";

export interface IContextMenuButtonProps extends IButtonProps {
    /**
     * Override the default button text color. Must be a supported
     * [**Color**](/docs/foundations-colors--docs).
     *
     * **NOTE** - Use sparingly as this may be removed in the future.
     * @type Color
     */
    color?: Color;
}

/**
 * ```js
 * import { ContextMenuButton } from "ui";
 * ```
 *
 * The **ContextMenuButton** is a button specifically for use inside the
 * [**ContextMenu**](/docs/contextmenu-contextmenu--docs) component.
 *
 * The children can contain **Text** and **Icon** content only.
 */
export default function ContextMenuButton(props: IContextMenuButtonProps) {
    return (
        <Root
            onClick={props.onClick}
            block
            disabled={!!props.disabled}
            overrideFontColor={props.color}
        >
            {props.children}
        </Root>
    );
}

const Root = styled(Button)<{ overrideFontColor?: Color }>`
    padding: ${spacing100};
    white-space: nowrap;
    transition: background-color 0.2s;

    // Remove the margin on icon only buttons
    > :not(:only-child) {
        margin-right: 1ch;
    }

    &:hover {
        background-color: ${grey100};
    }

    color: ${(props) =>
        !!props.overrideFontColor ? props.overrideFontColor : "inherit"};
`;
