import React from "react";
import styled from "styled-components";
import { spacing100 } from "ui/spacing";

interface IHeadingProps {
    bold?: boolean;
    allowNewLine?: boolean;
    children: React.ReactNode;
    dataCy?: string;
}
export default function Heading(props: IHeadingProps) {
    return (
        <Root
            bold={!!props.bold}
            allowNewLine={!!props.allowNewLine}
            data-cy={props.dataCy}
        >
            {props.children}
        </Root>
    );
}

const Root = styled.h1<{ bold: boolean; allowNewLine: boolean }>`
    margin-bottom: ${spacing100};
    font-size: 2em;
    font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
    white-space: ${({ allowNewLine }) =>
        allowNewLine ? "pre-line" : "normal"};
`;
