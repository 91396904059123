import React from "react";
import styled from "styled-components";

import Paragraph from "ui/Paragraph/Paragraph";
import Text from "ui/Text/Text";
import { spacing100 } from "ui/spacing";
import { desktop } from "uiKit/theme/mediaQueries";

import backgroundImageFull from "ui/MarketingHeroTitle/images/marketing-hero-title-background-full.svg";
import backgroundImageDesignOnly from "ui/MarketingHeroTitle/images/marketing-hero-title-background-design-only.svg";

interface IProps {
    /**
     * The title to display in bold.
     * @type string
     */
    title: string;

    /**
     * The description to display below the title.
     * @type string
     */
    description: string;
}

/**
 *  ```js
 * import { MarketingHeroTitle } from "ui";
 * ```
 * The **MarketingHeroTitle** component is intended to be positioned just below the navigation
 * bar on marketing pages, displaying a prominent title and description that reflect the
 * rest of the page.
 */

export default function MarketingHeroTitle(props: IProps) {
    return (
        <Root>
            <div>
                <Title>{props.title}</Title>
                <Paragraph>
                    <Text>{props.description}</Text>
                </Paragraph>
            </div>
        </Root>
    );
}

const Root = styled.div`
    min-height: 12.5em;

    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    padding: ${spacing100};

    background-color: white;
    background-image: url(${backgroundImageDesignOnly});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    ${desktop`
        background-image: url(${backgroundImageFull});
    `}
`;

const Title = styled.h1`
    font-weight: bold;
`;
