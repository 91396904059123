import styled from "styled-components";

import Button from "ui/Button/Button";
import { grey100 } from "ui/colors";
import { spacing75 } from "ui/spacing";

const DeleteButton = styled(Button)`
    height: 3em;
    padding: calc(${spacing75});
    border-radius: 8px;

    // We need to define this again for the
    // cases where we're using the buttons
    // as the root for the Primary/Secondary
    // button styled links.
    line-height: 1.5;

    transition: background-color 0.2s;
    text-align: center;

    &:hover {
        background-color: ${grey100};
    }
`;
export default DeleteButton;
