import React, {
    forwardRef,
    useEffect,
    useRef,
    useState,
    RefObject,
    useCallback,
} from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import debounce from "lodash.debounce";

import useEscapeKeyStack from "ui/_shared/hooks/useEscapeKeyStack";

import { Button } from "ui";
import { blue400 } from "ui/colors";
import { spacing75, spacing50 } from "ui/spacing";
import { IconContextMenu } from "ui/icons";

import { appFontSize } from "uiKit/theme/fonts";

import { IContextMenuButtonProps } from "./ContextMenuButton";
import { IContextMenuConfirmProps } from "./ContextMenuConfirm";

// Distance from the edge of the screen before we try and reposition.
const WINDOW_BUFFER_DISTANCE = 50; //px

// Inject the DOM element for the root element on first import
// of this file, so we don't need to keep track of this in other
// files.
export const CONTEXT_MENU_ROOT_ID = "ui/contextMenuRoot";
if (!!global.document && !document.getElementById(CONTEXT_MENU_ROOT_ID)) {
    const contextMenuRoot = document.createElement("div");
    contextMenuRoot.id = CONTEXT_MENU_ROOT_ID;
    document.body.prepend(contextMenuRoot);
}

interface IContextMenuProps {
    /**
     * Requires multiple `<ContextMenuButton>` or `<ContextMenuConfirm>`
     * elements.
     *
     * @type <ContextMenuButton> | <ContextMenuConfirm>
     */
    children: Array<
        | React.ReactElement<IContextMenuButtonProps>
        | React.ReactElement<IContextMenuConfirmProps>
    >;

    /**
     * Optional element to observe scroll on, so we can reposition the
     * context menu if the menu button is going to move on the scroll
     * event.
     *
     * For example when having a context menu in the sidebar or in a
     * modal that has overflow that scrolls.
     * @default window
     * @type RefObject<HTMLElement>
     */
    scrollContainerRef?: RefObject<HTMLElement> | RefObject<null>;
}

/**
 * ```js
 * import { ContextMenu } from "ui";
 * ```
 *
 * The **ContextMenu** contains a list of buttons that can be toggled opened
 * or closed. This is useful for hiding secondary actions from the main UI, as
 * well as saving space for the other things.
 *
 * The component will render a root button and manage it's internal states.
 * The `children` of this component will represent the list of buttons. The
 * `children` must be of type [**ContextMenuButton**](/docs/contextmenu-contextmenubutton--docs)
 * or [**ContextMenuConfirm**](/docs/contextmenu-contextmenuconfirm--docs)
 *
 * **NOTE** - Context menus don't support single item lists, you should consider
 * lifting the single element out and replacing the context menu button instead.
 *
 * **NOTE** - If you are using the **ContextMenu** inside an out-of-flow component
 * like a **Modal** or the **Sidebar**, you'll need to set the `scrollContainerRef` prop to
 * a ref of the HTML element that's managing the scrollbar.
 */
export default function ContextMenu(props: IContextMenuProps) {
    const [menuOpen, setMenuOpen] = useState(false);
    const menuButtonRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);

    // Store this in a function, so we can use the reference in
    // the document listener.
    const closeMenu = useCallback((event) => {
        // If we receive preventDefault from the event like we do
        // with the message section of the confirm message, then
        // don't close the menu automatically.
        if (!event.defaultPrevented) {
            setMenuOpen(false);
        }
    }, []);

    const positionMenu = useCallback(() => {
        if (menuRef.current && menuButtonRef.current) {
            const {
                top: menuButtonTop,
                bottom: menuButtonBottom,
                left: menuButtonLeft,
                right: menuButtonRight,
            } = menuButtonRef.current.getBoundingClientRect();
            const { scrollX, scrollY, innerHeight } = window;

            // If we pass in a scrollContainer element, and the button
            // disappears into the overflow of the element, then we want
            // to automatically close the menu so that it doesn't appear
            // off in the ether of the page.
            if (props.scrollContainerRef?.current) {
                const {
                    top: scrollContainerTop,
                    bottom: scrollContainerBottom,
                    left: scrollContainerLeft,
                    right: scrollConttainerRight,
                } = props.scrollContainerRef.current.getBoundingClientRect();

                // Decided to use the bottom right corner as the trigger
                // point for when to auto hide the menu, because it
                // FeltRight™.
                //
                // Given we position top left and read left to right, and
                // top to bottom, the bottom right corner is the last thing
                // we usually process.
                if (
                    menuButtonBottom < scrollContainerTop ||
                    menuButtonBottom > scrollContainerBottom ||
                    menuButtonRight < scrollContainerLeft ||
                    menuButtonLeft > scrollConttainerRight
                ) {
                    setMenuOpen(false);

                    // Return early here, because we've closed the menu or
                    // are about to. So there's no point positioning it.
                    //
                    // Additionally `menuRef` here has already been set to
                    // null, as it stays up to date with the virtualDOM at
                    // all times, even if the changes haven't been rendered
                    // yet. So the rest of this function explodes because
                    // menuRef isn't a DOM element anymore.
                    return;
                }
            }

            // We can get the width and height of the menu at this stage
            // even though it's not fully rendered on the page.
            const { width: menuWidth, height: menuHeight } =
                menuRef.current.getBoundingClientRect();

            // Is the left of the menu within our buffer distance.
            // If so, position the menu to hang to the left of the button
            // instead of the right.
            if (menuButtonLeft - menuWidth < WINDOW_BUFFER_DISTANCE) {
                menuRef.current.style.left = `${menuButtonLeft + scrollX}px`;
            } else {
                menuRef.current.style.left = `${
                    menuButtonRight + scrollX - menuWidth
                }px`;
            }

            // Is the bottom of the menu within our buffer distance.
            // If so, position the menu appear above the button instead
            // of the bottom.
            if (
                menuButtonBottom + menuHeight >
                innerHeight - WINDOW_BUFFER_DISTANCE
            ) {
                menuRef.current.style.top = `${
                    menuButtonTop + scrollY - menuHeight
                }px`;
            } else {
                menuRef.current.style.top = `${menuButtonBottom + scrollY}px`;
            }
        }
    }, [props.scrollContainerRef]);

    useEffect(() => {
        if (menuOpen) {
            document.body.addEventListener("click", closeMenu);
            return () => {
                document.body.removeEventListener("click", closeMenu);
            };
        }
    }, [menuOpen, closeMenu]);

    useEffect(() => {
        if (menuRef.current) {
            // We need an observer for when the confirm menu switches
            // state and causes the whole context menu to change size.
            //
            // Otherwise, the menu may become wider and end up off the
            // screen.
            const observer = new MutationObserver(positionMenu);
            observer.observe(menuRef.current, {
                childList: true,
                subtree: true,
            });

            // Repostion on window resize as well.
            const debouncePositionMenu = debounce(positionMenu, 100);
            window.addEventListener("resize", debouncePositionMenu);

            // Dereference this from the ref so that we have a stable pointer
            // to the DOM element to use in the cleanup function, because if
            // the ref changes between here and the cleanup, we'd be unable to
            // run the removeEventListener on the original element we added it to.
            const scrollContainerEl = props.scrollContainerRef?.current;
            if (scrollContainerEl) {
                // Manually reposition the menu when scrolling a scrollContainer
                // as the context menu itself is positioned in the global space,
                // so we need to tell it that it's menu button has moved.
                //
                // We don't need to do this in cases where the content is positioned
                // in the normal flow of the window, because the button and menu move
                // as one.
                scrollContainerEl.addEventListener("scroll", positionMenu);
            }

            // Initial positioning.
            positionMenu();

            return () => {
                if (scrollContainerEl) {
                    scrollContainerEl.removeEventListener(
                        "scroll",
                        positionMenu,
                    );
                }
                window.removeEventListener("resize", debouncePositionMenu);
                observer.disconnect();
            };
        }
    }, [menuOpen, positionMenu, props.scrollContainerRef]);

    return (
        <Root ref={menuButtonRef}>
            <MenuButton
                onClick={(event) => {
                    // This is here primarily to make sure the examples
                    // in Storybook work properly, because Storybook tries
                    // to be smart with events, and ends up reloading the
                    // example onClick, which prevents the the menu from
                    // ever opening.
                    //
                    // This isn't really an issue with our implementation
                    // so it doesn't hurt to be here all the time.
                    event.preventDefault();
                    setMenuOpen(!menuOpen);
                }}
            >
                <IconContextMenu />
            </MenuButton>
            {menuOpen &&
                createPortal(
                    <Menu ref={menuRef} onEscapeKey={closeMenu}>
                        {props.children}
                    </Menu>,
                    document.getElementById(CONTEXT_MENU_ROOT_ID)!,
                )}
        </Root>
    );
}
const Root = styled.div`
    // In the case of parent elements of ContextMenu being flexed, this makes
    // sure that the opened menu sticks to the bottom of MenuButton and
    // not the container
    align-self: flex-start;

    // Fix for the Storybook preview, because the context menu gets the whole
    // width of the preview container, so the menu appears far off to the
    // right on wide screens and looks broken.
    max-width: fit-content;
`;
const MenuButton = styled(Button)`
    padding: ${spacing75} ${spacing50};

    &:hover {
        color: ${blue400};
    }
`;

// Internal Menu component to register the escape callback when the menu is
// open (rendered) only. If we add the hook directly to ContextMenu then it'll
// be registered when the ContextMenu renders the button on the screen.
interface IMenuProps {
    onEscapeKey?: (event) => void;
    children?: Array<
        | React.ReactElement<IContextMenuButtonProps>
        | React.ReactElement<IContextMenuConfirmProps>
    >;
}
const Menu = forwardRef<HTMLDivElement, IMenuProps>(function Menu(props, ref) {
    useEscapeKeyStack(function (event) {
        if (props.onEscapeKey) {
            props.onEscapeKey(event);
        }
    });

    return <MenuRoot ref={ref}>{props.children}</MenuRoot>;
});
const MenuRoot = styled.div`
    position: absolute;
    min-width: 12em;

    background: white;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);

    // Make sure the button hover state doesn't overflow over
    // the rounded corners of the menu.
    overflow: hidden;

    // TODO: Clean this up when we sort out the difference
    // in marketing and app font sizes. We need this now because
    // the context menu root is a child of the body, not the app.
    font-size: ${appFontSize};
`;
