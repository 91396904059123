import React from "react";
import styled from "styled-components";

import { type Icon } from "ui/icons";
import { type Text } from "ui";

export interface IButtonProps {
    /**
     * Make the button act like a block element
     * @default false
     */
    block?: boolean;

    /**
     * Add custom classNames on the to button component.
     * Exists as a TODO, while we understand the needs of buttons.
     * @default false
     * @deprecated
     */
    className?: string;

    /**
     * Supports `<Text>` and `<Icon>` components only.
     * @type <Text> | <Icon>
     */
    children: Text | Icon | Array<Text | Icon>;

    /**
     * Disables the button
     * @default false
     */
    disabled?: boolean;

    /**
     * Set the button type. Defaults to button
     */
    type?: "submit" | "reset";

    /**
     * Event callback for when a button is clicked on.
     */
    onClick?: (event: React.MouseEvent) => void;

    dataCy?: string;
}

export default function Button(props: IButtonProps) {
    if (!props.type && !props.onClick) {
        throw new SyntaxError("type or onClick prop must be set.");
    }

    return (
        <Root
            onClick={props.onClick}
            block={!!props.block}
            disabled={!!props.disabled}
            type={props.type || "button"}
            data-cy={props.dataCy}
        >
            <span className={props.className}>{props.children}</span>
        </Root>
    );
}

const Root = styled.button<{ block?: boolean }>`
    width: ${(props) => (props.block ? "100%" : "auto")};

    line-height: 1.5;

    > span {
        display: block;
    }

    &:disabled {
        opacity: 0.3;
        pointer-events: none;
    }
`;
