// Spacing (https://www.figma.com/file/9m8FYvxfOx0DsyzO2f2De6/ClearCalcs-Design-System-2022?type=design&node-id=63-520&t=AxGKkzXjP4iKk0X2-0)

// Assumptions:
//
// - Base Unit is 1em (assuming default browser setting of 1em = 16px)
// Using this ratio, means everything will scale correctly, if that is not the default.
const spacing25 = ".25em"; // 4px
const spacing50 = ".5em"; // 8px
const spacing75 = ".75em"; // 12px
const spacing100 = "1em"; // 16px
const spacing125 = "1.25em"; // 20px
const spacing150 = "1.5em"; // 24px
const spacing175 = "1.75em"; // 28px
const spacing200 = "2em"; // 32px
const spacing225 = "2.25em"; // 36px
const spacing250 = "2.5em"; // 40px
const spacing400 = "4em"; // 64px

export {
    spacing25,
    spacing50,
    spacing75,
    spacing100,
    spacing125,
    spacing150,
    spacing175,
    spacing200,
    spacing225,
    spacing250,
    spacing400,
};

export type Spacing =
    | typeof spacing25
    | typeof spacing50
    | typeof spacing75
    | typeof spacing100
    | typeof spacing125
    | typeof spacing150
    | typeof spacing175
    | typeof spacing200
    | typeof spacing225
    | typeof spacing250
    | typeof spacing400;
