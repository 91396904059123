import React, { useId } from "react";
import styled, { css } from "styled-components";
import EditableSheetTemplateWidgetsCollection from "data/collections/EditableSheetTemplateWidgets";
import { useCallback, useState } from "react";
import { MentionsInput } from "react-mentions";

import SymbolHighlight from "./components/SymbolHighlight";
import SuggestionList from "./components/SuggestionList";
import { spacing100, spacing50, spacing75 } from "ui/spacing";
import { blue400, grey300, red100, red400 } from "ui/colors";
import Paragraph from "ui/Paragraph/Paragraph";
import { IconTriangleExclamationFilled } from "ui/icons";

export const AUTOCOMPLETE_ROOT_ID = "ui/editorAutocompleteRoot";
if (!!global.document && !document.getElementById(AUTOCOMPLETE_ROOT_ID)) {
    const autocompleteRoot = document.createElement("div");
    autocompleteRoot.id = AUTOCOMPLETE_ROOT_ID;
    document.body.prepend(autocompleteRoot);
}

interface IProps {
    /**
     * Value for the content of the text area.
     */
    value: string | null;

    /**
     * Callback for when the value is changed.
     */
    onValueChange: (value: string) => void;

    /**
     * Boolean to flag the error styling onto the textarea.
     * @default false
     */
    error?: boolean;

    /**
     * Optional error message that will be shown below the textarea.
     * If you're displaying the error message elsewhere nearby, there
     * is no need to add this here as well.
     */
    errorMessage?: string;

    /**
     * Children are used for the label of the textarea. Supports
     * Text and Icon components only.
     * @type Text | Icon | Array<Text | Icon>
     */
    children?: React.ReactNode;

    /**
     * Optional ref that can be passed in. It will be attached to the
     * textarea element for use outside the component to focus the
     * textarea.
     */
    textareaRef?: React.RefObject<HTMLTextAreaElement>;

    /**
     * Collection of widgets to use for filtering the symbols. and
     * showing the tooltips.
     */
    editableWidgets: EditableSheetTemplateWidgetsCollection;
}

/**
 *  ```js
 * import { EquationEditor } from "ui";
 * ```
 *
 * The **EquationEditor** component is a modified TextArea component that allows for symbol
 * autocomplete and highlighting.
 *
 * The caveat of this is that it requires a collection of widgets to be passed in to filter
 * the symbols and show the tooltips. Which makes it less reusable than other UI components.
 */
export default function EquationEditor(props: IProps) {
    const id = useId();
    const [focussed, setFocussed] = useState(false);

    function onChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        props.onValueChange(event.target.value);
    }
    function onFocus(event: React.FocusEvent<HTMLTextAreaElement>) {
        setFocussed(true);
    }
    function onBlur(event: React.FocusEvent<HTMLTextAreaElement>) {
        setFocussed(false);
    }

    const symbolFilter = useCallback(
        (search, callback) => {
            const models = props.editableWidgets.models
                .filter(
                    (model) =>
                        model.attributes.symbol &&
                        (model.attributes.symbol?.startsWith(search) ||
                            model.attributes.referenceId.startsWith(search) ||
                            model.attributes.label
                                ?.toLowerCase()
                                .includes(search.toLowerCase())),
                )
                .slice(0, 10);
            callback(
                models.map((model) => ({
                    id: model.attributes.referenceId,
                    display:
                        model.attributes.symbol +
                        " - " +
                        (model.attributes.label || "[No Label]"),
                })),
            );
        },
        [props.editableWidgets.models],
    );

    return (
        <Root error={!!props.error}>
            <MentionsInput
                value={props.value}
                onChange={onChange}
                suggestionsPortalHost={document.getElementById(
                    AUTOCOMPLETE_ROOT_ID,
                )}
                inputRef={props.textareaRef}
                customSuggestionsContainer={(children) => (
                    <SuggestionList>{children}</SuggestionList>
                )}
                onFocus={onFocus}
                onBlur={onBlur}
            >
                <SymbolHighlight
                    data={symbolFilter}
                    editableWidgets={props.editableWidgets}
                />
            </MentionsInput>
            {props.children && (
                <Label
                    htmlFor={id}
                    focussed={focussed}
                    hasValue={!!props.value}
                >
                    {props.children}
                </Label>
            )}
            {props.error && props.errorMessage && (
                <Paragraph color={red400}>
                    <IconTriangleExclamationFilled />
                    &nbsp;{props.errorMessage}
                </Paragraph>
            )}
        </Root>
    );
}

const Root = styled.span<{ error: boolean }>`
    display: block;
    width: 100%;
    min-height: 6em;

    position: relative;

    // This is the wrapper to React Mentions.
    > div {
        min-height: inherit;
        padding: calc(${spacing75} - 2px);

        background-color: ${(props) => (props.error ? red100 : "white")};
        border-radius: 8px;
        border: 2px solid transparent;

        // Set the line height to 1.5 to match the textarea.
        line-height: 1.5;

        // This is the text content we're storing in the div to keep
        // the spacing consistent. By default it's hidden, and it uses the
        // textarea's text to display the content.
        //
        // But because we want render the highlights in bold and and a
        // different font size, we going to rely on these styles to show
        // the text. Otherwise the textarea text will sit over the top of
        // the highlights.
        span {
            // Force all spans to have a z-index, so we can stack them
            // nicely, they all start at 2, and we reduce the z-index
            // on the SymbolHighlight component to 1, so that it doesn't
            // cover other content.
            position: relative;
            z-index: 2;

            // Override all the react-mentions styles, to make sure this
            // content is the one we're always showing.
            color: black;
            visibility: visible !important;
        }

        & > div > div {
            border: 2px solid transparent !important;
            overflow: visible !important;
        }
    }
    textarea {
        // React Mentions forces the textarea to be position absolute,
        // so lets make sure it's over the top of the content, so text
        // selection works.
        z-index: 3;

        // Remove the border width to keep the height at a flat
        // 3em.
        padding: calc(${spacing75} - 2px);
        min-height: inherit;

        border: 2px solid;
        border-radius: 8px;
        border-color: ${(props) => (props.error ? red400 : grey300)};

        transition-property: border-color, background-color;
        transition-duration: 0.3s;

        // Hide the textarea text so we can format the highlights, we
        // need to reset the caret to show up now though.
        color: transparent;
        caret-color: black;

        &:focus {
            border-color: ${blue400};
            // Remove the default styling.
            outline: none;
        }
    }
`;

const Label = styled.label<{
    focussed: boolean;
    hasValue: boolean;
}>`
    position: absolute;
    top: 0;
    left: 0;
    padding: ${spacing75};

    // Make sure the label is always on top of the textarea.
    z-index: 4;

    // Prevent click for the label so we can focus
    // the input underneath.
    pointer-events: none;

    color: ${grey300};

    transition-property: font-size, padding, transform, color, background-color;
    transition-duration: 0.3s;

    ${(props) =>
        (props.focussed || props.hasValue) &&
        css<any>`
            font-size: 0.75em;
            padding: 0 ${spacing50};
            transform: translateY(-50%) translateX(${spacing100});
            background-color: white;
            color: black;
        `}
`;
