import React from "react";
import styled from "styled-components";

import RawLink, { type ICommonProps } from "./internal/RawLink";
import PrimaryButton from "ui/Button/PrimaryButton";

interface IPrimaryButtonLinkProps extends ICommonProps {
    /**
     * Force the button link to be a block element.
     * @default false
     */
    block?: boolean;
}

/**
 *  ```js
 * import { PrimaryButtonLink } from "ui";
 * ```
 *
 * The **PrimaryButtonLink** component represents a Link in the style of a
 * [**PrimaryButton**](/docs/buttons-primarybutton--docs). Because of this,
 * they offer no customization, such as `inheritColor` or `supressHoverState`.
 *
 * The implement all the standard properties of a [**Link** (see Docs)](/docs/link-link--docs).
 */
export default function PrimaryButtonLink(props: IPrimaryButtonLinkProps) {
    return (
        <PrimaryButton as={StyledLink} {...props}>
            {props.children}
        </PrimaryButton>
    );
}

const StyledLink = styled(RawLink)<{ block?: boolean }>`
    display: ${(props) => (!!props.block ? "block" : "inline-block")};
`;
