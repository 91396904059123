import styled from "styled-components";

import { Button } from "ui";
import { blue400 } from "ui/colors";

export const SIDEBAR_NAV_WIDTH = "3em";

// TODO: This should probably live in the SidebarLink
// component, but this will do for now.
export const ACTIVE_LINK_CLASS = "SidebarLink_active";

/**
 * Internal component only.
 *
 * Used as a base component for the SidebarLink,
 * as well as the button controls in the sidebar.
 */
const SidebarButton = styled(Button)`
    // Override the Button, display:block here so
    // that we can nicely center the icons inside
    // the button.
    //
    // It's not ideal as I don't think you should
    // really be flexing button content in normal
    // circumstances. But given this is contained
    // here and the child will only ever be a single
    // icon, I'm ok to leave this for now.
    display: flex !important;
    width: ${SIDEBAR_NAV_WIDTH};
    height: ${SIDEBAR_NAV_WIDTH};

    justify-content: center;
    align-items: center;

    > * {
        // Increase the icons size.
        font-size: 1.5em;
    }

    transition: color 0.5s;

    &:hover,
    &.${ACTIVE_LINK_CLASS} {
        color: ${blue400};
    }
`;
export default SidebarButton;
