import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { grey200, red500, grey100 } from "ui/colors";
import { Paragraph } from "ui";

import FloatingBox from "uiKit/floatingBox/FloatingBox";
import MathDisplay from "math/components/MathDisplay";
import EditableSheetTemplateWidgetsCollection from "data/collections/EditableSheetTemplateWidgets";
import { appFontSize } from "uiKit/theme/fonts";

interface IProps {
    display?: string;
    data: (search: string, callback: (data: any[]) => void) => void;
    editableWidgets: EditableSheetTemplateWidgetsCollection;
}

export default function SymbolHighlight(props: IProps) {
    const [tooltip, setTooltip] = useState(false);
    const highlightEl = useRef(null);

    // Because the textarea sits over the top of where the symbol highlights
    // live so that text selection still works. We need to manually capture
    // the mousemove event to determine if the mouse is over the highlight.
    useEffect(() => {
        function onMouseMove(event) {
            if (!highlightEl.current) return;

            setTooltip(
                document
                    .elementsFromPoint(event.clientX, event.clientY)
                    .indexOf(highlightEl.current) >= 0,
            );
        }

        document.addEventListener("mousemove", onMouseMove);
        return () => {
            document.removeEventListener("mousemove", onMouseMove);
        };
    });

    const currentWidget = props.editableWidgets.getByReferenceId(
        props.display!,
    );

    return (
        <Root ref={highlightEl} error={!currentWidget}>
            {props.display}
            {tooltip && currentWidget && (
                <Tooltip>
                    <Paragraph>
                        <span>{currentWidget.attributes.label}</span>
                        &nbsp;&nbsp;
                        <MathDisplay
                            math={currentWidget.attributes.symbol || ""}
                        />
                    </Paragraph>
                </Tooltip>
            )}
        </Root>
    );
}

// This component is pretending to be a react-mentions Mention component,
// and it relies on these defaultProps to exist.
SymbolHighlight.defaultProps = {
    trigger: "@",
    markup: "`__id__`",
    displayTransform: function (id, display) {
        return display || id;
    },
    onAdd: () => null,
    onRemove: () => null,
    renderSuggestion: null,
    isLoading: false,
    appendSpaceOnAdd: false,
};

const Root = styled.span<{ error: boolean }>`
    position: relative;
    display: inline-block;

    // Because we're using box-shadow to create a fake border, so we don't
    // blow out the layout on the highlights, we want to make sure the highlights
    // show up under the rest of the content.
    //
    // This overrides the z-index set on the spans in EquationEditor.
    z-index: 1 !important;

    background: ${(props) => (props.error ? red500 : grey100)};
    color: ${(props) => (props.error ? "white" : "black")} !important;
    border-radius: 2px;

    // Because the content needs to mirror the textarea, we can't add padding
    // to the highlight. So we're going to use a box-shadow to fake it.
    box-shadow: 0 0 0px 2px ${(props) => (props.error ? red500 : grey100)};
`;

const Tooltip = styled(FloatingBox)`
    padding: 0.5em;

    background-color: white;
    border: 1px solid ${grey200};
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);

    // We need to override the font size here, as the
    // global floating box div is outsize the app.
    font-size: ${appFontSize};
`;
