import styled from "styled-components";

import UIButton from "./internal/UIButton";
import { blue500, blue400 } from "ui/colors";

/**
 *  ```js
 * import { PrimaryButton } from "ui";
 * ```
 *
 * For the principal call to action on the page. **PrimaryButton**s
 * should only appear once per screen (not including the application header,
 * modal dialog, or side panel).
 */
const PrimaryButton = styled(UIButton)`
    color: white;
    background-color: ${blue400};

    &:hover {
        background-color: ${blue500};
    }
`;
export default PrimaryButton;
