import React, { useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import track from "track";

import { blue400, grey300 } from "ui/colors";

import SidebarContext from "./context";
import SidebarNav from "./SidebarNav";
import {
    IconPin,
    IconUnpin,
    IconCross,
    IconFullscreen,
    IconExitFullscreen,
    IconExternalLinkAlt,
} from "ui/icons";
import { default as SidebarButton, SIDEBAR_NAV_WIDTH } from "./internal/button";
import useEscapeKeyStack from "ui/_shared/hooks/useEscapeKeyStack";
import { spacing25, spacing50, spacing125 } from "ui/spacing";
import Link from "ui/Link/Link";

// Fixed size of the content area
const SIDEBAR_CONTENT_WIDTH = "35em";

interface IProps {
    /**
     * Accepts any content, though if the first child is
     * a SidebarNav Element, it will extract the contents
     * in to nav bar.
     * @type ReactElement<SidebarNav | any>
     */
    children?: React.ReactElement<typeof SidebarNav | any>[];
}
export default function Sidebar(props: IProps) {
    const {
        portalEl,
        contentTop,
        contentWidth,
        opened,
        pinned,
        setPinned,
        expanded,
        setExpanded,
        title,
        closeSidebar,
        externalLink,
    } = useContext(SidebarContext);

    const [contentMaxWidth, setContentMaxWidth] = useState(contentWidth);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (contentRef.current) {
            // On browsers When scrollbars are always shown (like on windows, or
            // when they're forced on in OSX), Chrome doesn't take the scrollbar
            // in to account when computing max-width. So the sidebar overflows
            // under the left hand sidebar.
            //
            // This computes the width of the scrollbar and removes it from the
            // content width. We also force the scrollbar to be visible in full
            // screen mode (see [1]), to make sure this calculation remains constant.
            const contentScrollbarWidth =
                contentRef.current.offsetWidth - contentRef.current.clientWidth;
            setContentMaxWidth(contentWidth - contentScrollbarWidth);
        }
    }, [contentWidth]);

    useEscapeKeyStack(closeSidebar!);

    // If we haven't received a portalEl yet, let's not try to render.
    if (!portalEl) return null;

    let additionalNav;
    let content;
    if (props.children) {
        // Make sure if there is a SidebarNav element it's the first
        // child in the list, otherwise throw an error in development.
        if (process.env.NODE_ENV !== "production") {
            const childrenArray = React.Children.toArray(props.children);
            const sidebarNav = childrenArray.find((component) => {
                return (component as any).type === SidebarNav;
            });
            if (sidebarNav && childrenArray.indexOf(sidebarNav) !== 0) {
                throw new ReferenceError("SidebarNav must be the first child");
            }
        }

        // If the first child is a SidebarNav, extract it out so we
        // can put the contents in the nav bar on the side.
        if (props.children[0].type === SidebarNav) {
            [additionalNav, ...content] = props.children;
        } else {
            content = props.children;
        }
    }

    return createPortal(
        <Root>
            <SidebarNavRoot contentTop={contentTop}>
                {additionalNav}
            </SidebarNavRoot>
            <SidebarContent
                ref={contentRef}
                contentTop={contentTop}
                contentMaxWidth={contentMaxWidth}
                opened={opened}
                expanded={expanded}
            >
                <SidebarContentHeader>
                    <Title>{title}</Title>
                    {externalLink && (
                        <HeaderControl
                            as={Link}
                            onClick={() => {
                                track("Sidebar Help Clicked", {
                                    context: title,
                                });
                            }}
                            href={externalLink}
                            inheritColor
                        >
                            <IconExternalLinkAlt />
                        </HeaderControl>
                    )}
                    <HeaderControl
                        onClick={() => {
                            setPinned!(!pinned);
                        }}
                    >
                        {pinned ? <IconUnpin /> : <IconPin />}
                    </HeaderControl>
                    <HeaderControl
                        onClick={() => {
                            setExpanded!(!expanded);
                        }}
                    >
                        {expanded ? <IconExitFullscreen /> : <IconFullscreen />}
                    </HeaderControl>
                    <HeaderControl onClick={closeSidebar!}>
                        <IconCross />
                    </HeaderControl>
                </SidebarContentHeader>
                <ContentWrapper
                    expanded={expanded}
                    contentWidth={contentMaxWidth}
                >
                    {content}
                </ContentWrapper>
            </SidebarContent>
        </Root>,
        portalEl,
    );
}

const Root = styled.div`
    height: 100%;
    display: flex;
`;

const SidebarColumn = styled.div<{ contentTop: number }>`
    height: calc(100vh - ${(props) => props.contentTop}px);

    position: sticky;
    top: ${(props) => props.contentTop}px;

    overflow-y: auto;

    background: white;
`;
const SidebarNavRoot = styled(SidebarColumn)`
    box-shadow: 0 4px 2px 2px rgba(0, 0, 0, 0.2);
    border-right: 1px solid ${grey300};
`;

const SidebarContent = styled(SidebarColumn)<{
    contentMaxWidth: number;
    opened: boolean;
    expanded: boolean;
}>`
    // We always want the sidebar to cap out as the maximal width
    // it could ever be. The ContentWrapper will restrict the actual
    // content to the sidebar width.
    max-width: ${(props) => {
        if (props.opened) {
            return `${props.contentMaxWidth}px`;
        } else {
            return "0em";
        }
    }};

    // Only tradeoff here is that we need to ease the animation to
    // be at the part of the slide out closest to the right hand
    // edge of the screen.
    transition: max-width 0.5s
        ${(props) => (props.opened ? "ease-in" : "ease-out")};

    // Hide the overflow, so we don't get weird text wrapping
    // as the sidebar slides close.
    overflow-x: hidden;

    // [1] - Force the scrollbar to be visible in fullscreen mode to
    // make sure we can consistently compute its width to avoid the sidebar
    // overflowing under the left hand sidebar.
    //
    // Note this will remain hidden on browsers that have hidden scrollbars
    // turned on by default.
    overflow-y: ${(props) => (props.expanded ? "scroll" : "auto")};
`;

const SidebarContentHeader = styled.header`
    padding: ${spacing25} ${spacing125};
    border-bottom: 1px solid ${grey300};
    display: flex;
    align-items: center;

    > :first-child {
        flex: 1;
    }
`;

const Title = styled.h3`
    margin-right: ${spacing50};
    overflow: hidden;

    color: ${blue400};
    font-weight: bold;

    text-overflow: ellipsis;
    white-space: nowrap;
`;

// We need to override the width of the buttons here
// to make them slightly smaller, because they were
// taking up too much space.
const HeaderControl = styled(SidebarButton)`
    width: 2.5em;
`;

const ContentWrapper = styled.div<{
    expanded: boolean;
    contentWidth: number;
}>`
    padding: ${spacing125};

    // Normally you shouldn't transition on width, but in this case
    // this element needs to have a set width to make the animation
    // work.
    //
    // I was juggling both min/max width here to make the animation
    // work in both directions, but it wasn't any more performant.
    //
    // NOTE - We need to remove the sidebar nav width so the tabs
    // still show up when expanded.
    width: ${(props) =>
        props.expanded
            ? `calc(${props.contentWidth}px - ${SIDEBAR_NAV_WIDTH})`
            : SIDEBAR_CONTENT_WIDTH};
    transition: width 0.5s ease-in-out;
`;
