import React, { useContext } from "react";
import { useRouter } from "next/router";

import { Link } from "ui";
import SidebarButton, { ACTIVE_LINK_CLASS } from "./internal/button";

import { SIDEBAR_QUERYSTRING_KEY } from "./SidebarManager";
export interface ISidebarLinkProps {
    /**
     * URL to navigate to. Supports external and internal URLs.
     * See `next/link` for further documentation on internal URLs
     */
    href: string;

    /**
     * Supports `<Icon>` components only.
     * @type <Icon>
     */
    children: React.ReactNode;
}
export default function SidebarLink(props: ISidebarLinkProps) {
    const router = useRouter();

    // The sidebar will always route with querystring
    // so lets smooth over that detail internally.
    const link = {
        query: {
            ...router.query,
            [SIDEBAR_QUERYSTRING_KEY]: props.href,
        },
    };

    return (
        <SidebarButton
            as={Link}
            href={link}
            inheritColor
            suppressHoverState
            scroll={false} // Retain the current scroll position opening sidebar.
            isActive={router.query[SIDEBAR_QUERYSTRING_KEY] === props.href}
            activeClassName={ACTIVE_LINK_CLASS}
        >
            {props.children}
        </SidebarButton>
    );
}
