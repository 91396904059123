import styled from "styled-components";

import UIButton from "./internal/UIButton";
import { grey200 } from "ui/colors";

/**
 *  ```js
 * import { SecondaryButton } from "ui";
 * ```
 *
 * For secondary actions on each page. **SecondaryButton**s can
 * only be used in conjunction with a primary button. As part of
 * a pair, the secondary button’s function is to perform the
 * negative action of the set, such as “Cancel” or “Back”.
 *
 * Do not use a secondary button in isolation, and do not use a
 * secondary button for a positive action.
 */
const SecondaryButton = styled(UIButton)`
    color: black;
    background-color: white;
    border: 1px solid black;

    &:hover {
        background-color: ${grey200};
    }
`;
export default SecondaryButton;
