import { useEffect } from "react";

let callbackStack: Array<Function> = [];
function onEscapeKeyUp(event) {
    if (event.key === "Escape") {
        callbackStack[0](event);
    }
}
export default function useEscapeKeyStack(callback: (event) => void) {
    useEffect(() => {
        // Push the callback on to the front of the stack, this makes
        // finding the most recent easy because it's at index 0, rather
        // than needing to compute index `n`.
        callbackStack.unshift(callback);

        // Setup the event listener, if we have a reason to.
        if (callbackStack.length === 1) {
            window.addEventListener("keyup", onEscapeKeyUp, false);
        }

        return () => {
            // Clean up the callback whenever the hooks cleans up. The
            // user of the escape key stack, should cause some event that
            // causes this to happen. Most likely a close event.
            callbackStack.splice(callbackStack.indexOf(callback), 1);

            // Clean up the event listener if the stack is empty...
            // Leave no trace behind!
            if (callbackStack.length === 0) {
                window.removeEventListener("keyup", onEscapeKeyUp, false);
            }
        };
    }, [callback]);
}
