import React from "react";
import styled from "styled-components";
import { usePathname } from "next/navigation";

import RawLink, { ICommonProps } from "./internal/RawLink";

import { blue400 } from "ui/colors";
import { spacing25 } from "ui/spacing";

// Internal Link Implementation Only.
export interface ILinkProps extends ICommonProps {
    /**
     * Add custom classNames on the to link component. Exists to avoid
     * code cleanup in NextJS Migration.
     * @deprecated
     */
    className?: string;

    /**
     * Inherit the font color of the current container.
     * @default false
     */
    inheritColor?: boolean;

    /**
     * Don't show the default hover state on the link. Will require
     * manual hover state if used
     * @default false
     */
    suppressHoverState?: boolean;

    /**
     * **Internal URLs Only** Set the className is the href matches the router location
     */
    activeClassName?: string;

    /**
     * **Internal URLs Only** Override for checking isActive state on a link
     */
    isActive?: boolean;
}

/**
 *  ```js
 * import { Link } from "ui";
 * ```
 *
 * The **Link** component should be used for a links, both internal and
 * external.
 *
 * Links have a default styling for use in most cases, if you want to implement something
 * more custom, you can use the `suppressHoverState` flag to remove the default hover
 * underline, but you'll need to implement a new hover state to keep the links accessible.
 *
 * Links are also blue by default, if you'd like to make links the same colour as surrounding
 * text, you can use the `inheritColor` flag.
 *
 * **External vs Internal Links**
 *
 * Internal links work as [NextJS Links](https://nextjs.org/docs/pages/api-reference/components/link)
 * with all the props being supported, as well as URL Objects as href. **Note** that props marked are
 * only available for internal links.
 *
 * External links work like any link to an external URL, ie. a URL that include the
 * protocol like  https://. They will be opened in a new tab by default. **Note** internal
 * links that are a full URL will also be treated as an external link
 * (ie. https://clearcalcs.com/support vs. /support)
 */
export default function Link(props: ILinkProps) {
    const pathname = usePathname();
    const {
        inheritColor,
        suppressHoverState,
        activeClassName,
        isActive,
        // These are the props for the RawLink component
        ...commonProps
    } = props;

    const computedIsActive =
        isActive !== undefined ? props.isActive : pathname === commonProps.href;

    const classNames = [
        commonProps.className,
        computedIsActive ? activeClassName : "",
    ].join(" ");

    return (
        <StyledLink
            {...commonProps}
            className={classNames}
            suppressHoverState={!!suppressHoverState}
            inheritColor={!!inheritColor}
        >
            {/*
             * TODO: Remove this span at some point in the future when we've fixed
             * all the places where we're using Link in legacy pages, it's superfluous
             * but we're abusing it to make things work
             */}
            <span>{props.children}</span>
        </StyledLink>
    );
}

const StyledLink = styled(RawLink)<{
    inheritColor: boolean;
    suppressHoverState: boolean;
}>`
    color: ${(props) => (props.inheritColor ? "currentColor" : blue400)};

    text-decoration: underline;
    text-decoration-color: transparent;
    text-decoration-thickness: 1px;
    text-underline-offset: ${spacing25};

    transition: text-decoration 0.5s;

    &:hover,
    &:focus {
        outline: none;
        text-decoration-color: ${(props) =>
            !props.suppressHoverState ? "currentColor" : "transparent"};
    }
`;
