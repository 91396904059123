import React from "react";
import { ISidebarLinkProps } from "./SidebarLink";

interface IProps {
    /**
     * Supports multiple `<SidebarLink>` elements.
     * @type <SidebarLink> | false
     */
    children:
        | React.ReactElement<ISidebarLinkProps>
        | Array<React.ReactElement<ISidebarLinkProps> | false>;
}

// This component exists so we can find it as a child
// of sidebar, and extract the content in to the sidebar
// nav.
export default function SidebarNav(props: IProps) {
    return <>{props.children}</>;
}
