import React, { useState } from "react";
import styled from "styled-components";
import { spacing100, spacing75 } from "ui/spacing";
import { blue400, grey300, red100, red400 } from "ui/colors";
import { Text } from "ui";
import { IconDragDrop, IconTriangleExclamationFilled } from "ui/icons";

interface IFileInputProps {
    error?: boolean;
    errorMessage?: string;
    onFileChange: (file: File | null) => void;
    accept?: string;
}
export default function FileInput(props: IFileInputProps) {
    const [focussed, setFocussed] = useState(false);

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        const inputFile = event.target.files?.item(0);
        if (inputFile) {
            props.onFileChange(inputFile);
        } else {
            props.onFileChange(null);
        }
    }
    function onFocus(event: React.FocusEvent<HTMLInputElement>) {
        setFocussed(true);
    }
    function onBlur(event: React.FocusEvent<HTMLInputElement>) {
        setFocussed(false);
    }

    return (
        <Root>
            <Label error={!!props.error}>
                <IconDragDrop size={24} />
                &nbsp;&nbsp;
                <Text>No file uploaded</Text>
                <BrowseButton focussed={focussed}>Browse files</BrowseButton>
                <InputEl
                    type="file"
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    accept={props.accept}
                />
            </Label>
            {props.error && props.errorMessage && (
                <Text color={red400}>
                    <IconTriangleExclamationFilled />
                    &nbsp;{props.errorMessage}
                </Text>
            )}
        </Root>
    );
}

const Root = styled.span`
    display: block;
    width: 100%;
    position: relative;
`;

const InputEl = styled.input`
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
`;

const Label = styled.label<{ error: boolean }>`
    display: flex;
    align-items: center;
    // Remove the border width to keep the height at a flat
    // 3em.
    padding: calc(${spacing75} - 2px) ${spacing100};

    border: 2px solid;
    border-radius: 8px;
    border-color: ${(props) => (props.error ? red400 : grey300)};
    background-color: ${(props) => (props.error ? red100 : "white")};

    transition-property: border-color, background-color;
    transition-duration: 0.3s;

    cursor: pointer;

    & > :nth-child(2) {
        flex: 1;
    }
`;

const BrowseButton = styled.span<{ focussed: boolean }>`
    display: inline-block;
    padding: calc(${spacing75} - 1px);

    border: 2px solid;
    border-radius: 8px;
    border-color: ${(props) => (props.focussed ? blue400 : grey300)};

    transition-property: border-color, background-color;
    transition-duration: 0.3s;

    &:hover {
        background-color: ${grey300};
    }
`;
