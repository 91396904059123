import Rollbar from "rollbar";

// Try and optimistically parse some file details from the data uri.
// This is not the best implementation, but it works okay for the
// icon uploads in the template editor.
//
// TODO - Is there a better way to deal with this?
export default function fileDetailsFromDataUri(upload: string) {
    if (!upload) return;
    try {
        const fileMimeType = upload.split(";")[0].split(":")[1];
        const classType = fileMimeType.split("/")[0];
        let extension = `.${fileMimeType.split("/")[1]}`;

        // Deal with text/plain and other text types specially.
        if (classType === "text") {
            extension = ".txt";
        } else if (extension.includes("+")) {
            extension = `${extension.split("+")[0]}`;
        }

        return {
            extension,
            classType,
        };
    } catch (error) {
        // @ts-ignore Rollbar typings are broken
        Rollbar.error(
            "Error parsing upload - doesn't appear to be a DataUri string.",
            {
                upload,
            },
            error,
        );
    }
}
