import React from "react";
import styled from "styled-components";

import RootCard, { ICardProps } from "./internal/RootCard";

import { grey100, grey300 } from "ui/colors";
import { Icon } from "ui/icons";
import { spacing75 } from "ui/spacing";

import { Text } from "ui";

interface ITitledCardProps extends ICardProps {
    /**
     * Class of an Icon to show in the title.
     * **Note** - This must be the IconClass not the component.
     * (ie. `IconTick` vs. `<IconTick />`)
     * @type Icon
     */
    Icon?: Icon;

    /**
     * Text to show in the title.
     * @type string
     */
    title: string;

    /**
     * Options description text for the card. Shown in
     * the title box.
     */
    description?: string;
}

/**
 *  ```js
 * import { TitledCard } from "ui";
 * ```
 *
 * The **TitledCard** component is a general purpose component for containing
 * general data. Unlike [**Card**](/docs/cards-card--docs), it has a built-in
 * configurable title sections above the content.
 */
export default function TitledCard(props: ITitledCardProps) {
    return (
        <Root>
            <TitleBox>
                <Title>
                    {props.Icon && <props.Icon />}
                    <Text bold>{props.title}</Text>
                </Title>
                {props.description && (
                    <Description>
                        <Text italic>{props.description}</Text>
                    </Description>
                )}
            </TitleBox>
            <Card>{props.children}</Card>
        </Root>
    );
}

const Root = styled(RootCard)`
    // Override the padding so the title sits flush
    // with the border,
    padding: 0;
`;

const TitleBox = styled.header`
    padding: ${spacing75};

    background: ${grey100};
    color: black;
    border-bottom: 1px solid ${grey300};
`;

const Title = styled.p`
    display: flex;
    align-items: center;

    > :nth-child(2) {
        margin-left: ${spacing75};
    }
`;

const Description = styled.p`
    font-size: 0.8em;
    margin-top: 0.5em;
`;

const Card = styled.div`
    // Re-add the padding on the content section.
    padding: ${spacing75};
`;
