import React, { MouseEvent, useState } from "react";
import NextLink from "next/link";

// From next/link types, as it's not exported by default
import { UrlObject } from "url";
import isExternalUrl from "../utils/isExternalURL";
// import router from "next/router";
import { useEffectOnce } from "framework/hooks/useEffectOnce";

export interface ICommonProps {
    /**
     * Supports `<Text>` components only.
     * @type <Text>
     */
    children: React.ReactNode;

    /**
     * Add custom classNames on the to link component. Unlike the Link component
     * this is not deprecated, because this component is used for building custom
     * links.
     */
    className?: string;

    /**
     * Where to display linked url, same as anchor target. **External URLs**
     * default to `_blank`
     *
     * @type _blank | _self
     * @default _self
     */
    target?: "_blank" | "_self";

    /**
     * URL to navigate to. Supports external and internal URLs.
     * See [NextJS Link](https://nextjs.org/docs/pages/api-reference/components/link) for further documentation on internal URLs
     */
    href: string | UrlObject;

    /**
     * **Internal URLs Only** Replace the current history state instead of adding a new url into the stack.
     * See [NextJS Link](https://nextjs.org/docs/pages/api-reference/components/link) for further documentation
     * @default false
     */
    replace?: boolean;

    /**
     * **Internal URLs Only** Prefetch the page content when the link appears in the viewport, will still
     * prefetch on hover. See [NextJS Link](https://nextjs.org/docs/pages/api-reference/components/link) for further documentation.
     * @default false
     */
    prefetch?: boolean;

    /**
     * **Internal URLs Only** Scroll to the top of the page after a navigation.
     * @default true
     */
    scroll?: boolean;

    /**
     * **Internal URLs Only** NextJS's shallow routing feature.
     * See [NextJS Link](https://nextjs.org/docs/pages/api-reference/components/link) for further documentation on internal URLs.
     * @default false
     */
    shallow?: boolean;

    /**
     * Event callback for when a link is clicked on.
     */
    onClick?: (event: MouseEvent) => void;

    /**
     * Cypress selector
     * TODO: Find a way to remove this noise, from production builds.
     */
    dataCy?: string;
}

export default function RawLink(props: ICommonProps) {
    // Caution:
    //
    // Any redirects added here will navigate to the Webflow published pages.
    // Ensure that Webflow does not have an inverse redirect to the app.
    // This will cause a redirect loop i.e. ERR_TOO_MANY_REDIRECTS
    // - Page has been ticketed as complete by marketing in:
    //   https://docs.google.com/spreadsheets/d/1B1zpnwD-GP50EwptqiWHAOYUVRG-z9FHyC0TrrJFq0Q/edit?usp=sharing
    // - Check on webflow that the page has been published and there are no redirects on that route
    //   see https://webflow.com/dashboard/sites/clearcalcs/publishing
    // Keep this list in sync  with client/config/app.js list of server-side redirects
    // Note we don't specify /:slug* here like in next.config.js redirects
    // because we search for paths that start with this string
    const WEBFLOW_PUBLISHED_PAGES = [
        "/cookie",
        "/privacy",
        "/terms",
        "/third-party-terms",
        "/blog",
        "/calculations",
        "/contact",
        "/customers",
        "/pricing",
        "/videos",
        "/webinars",
        "/support",
    ];

    const [redirectToWebflow, setRedirectToWebflow] = useState(false);

    useEffectOnce(() => {
        // For non-browser environments e.g. print, window will be undefined.
        // There will be no navigation happening anyway, so we can skip this.
        // This check is placed inside a useEffect because window may not be available
        // on first render.
        if (typeof window !== "undefined") {
            setRedirectToWebflow(
                WEBFLOW_PUBLISHED_PAGES.some(
                    (page) =>
                        props.href.toString().startsWith(page) &&
                        // Only apply redirect if accessing link via these subdomains
                        ["app", "esics", "itidesignspec"].some((word) =>
                            window.location.host.startsWith(word),
                        ),
                ),
            );
        }
    });

    if (typeof props.href === "string" && isExternalUrl(props.href)) {
        return (
            <a
                href={props.href as string}
                className={props.className}
                target={props.target || "_blank"}
                rel="noopener noreferrer"
                onClick={props.onClick}
                data-cy={props.dataCy}
            >
                {props.children}
            </a>
        );
    }

    // By default Next Link does a soft navigation which will never hit the server
    // and trigger nextjs redirects
    // So we need to use an anchor tag to force the round trip.
    if (redirectToWebflow) {
        return (
            <a
                href={props.href as string}
                className={props.className}
                data-cy={props.dataCy}
            >
                {props.children}
            </a>
        );
    }

    return (
        <NextLink
            href={props.href}
            passHref={true}
            prefetch={!!props.prefetch}
            shallow={!!props.shallow}
            // Scroll defaults to true in next/link, so the absence of the scroll prop (`undefined`)
            // should be treated as `true`. Only if the prop is actively set to `false` do we want
            // to prevent the scroll behaviour
            scroll={props.scroll !== false}
            className={props.className}
            onClick={props.onClick}
            data-cy={props.dataCy}
        >
            {props.children}
        </NextLink>
    );
}
