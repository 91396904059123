import { css } from "styled-components";

export const mobile = (...args) => css`
    @media (max-width: 600px) {
        ${css(...args)};
    }
`;

export const portraitTablet = (...args) => css`
    @media (max-width: 769px) {
        ${css(...args)};
    }
`;

export const homepageMenuTablet = (...args) => css`
    @media (max-width: 850px) {
        ${css(...args)};
    }
`;

export const landscapeTablet = (...args) => css`
    @media (min-width: 770px) {
        ${css(...args)};
    }
`;

export const desktop = (...args) => css`
    @media (min-width: 1025px) {
        ${css(...args)};
    }
`;
