import { createContext } from "react";

interface IContext {
    portalEl?: HTMLDivElement;
    contentTop: number;

    contentWidth: number;

    // Sidebar states:
    // - opened: Is the sidebar open at all?
    // - pinned: Is the sidebar pinned open?
    // - expanded: Is the sidebar expanded to fill the content area?
    opened: boolean;
    setOpened?: (value: boolean) => void;
    pinned: boolean;
    setPinned?: (value: boolean) => void;
    expanded: boolean;
    setExpanded?: (value: boolean) => void;

    title?: string;
    setTitle?: (title?: string) => void;
    currentUrl?: string;
    externalLink?: string;
    setExternalLink?: (href?: string) => void;

    // We only need to expose this so that the sidebar
    // can access it for the escape stack.
    closeSidebar?: () => void;
}
const SidebarContext = createContext<IContext>({
    portalEl: undefined,
    contentTop: 0,
    contentWidth: 0,
    opened: false,
    pinned: false,
    expanded: false,
});
export default SidebarContext;
