import { useContext, useEffect } from "react";
import SidebarContext from "./context";

interface IProps {
    /**
     * String for the title.
     * @type string
     */
    children?: string;

    /**
     * External URL to navigate to.
     * @type string
     */
    externalLink?: string;
}

// We're using children to hold our string instead of
// a prop, because it looks cleaner.
//
// <SidebarTitle>The Title</SidebarTitle>
//                  vs.
// <SidebarTitle title="The Title" />
export default function SidebarTitle(props: IProps) {
    const { setTitle, setExternalLink } = useContext(SidebarContext);
    useEffect(() => {
        setTitle!(props.children);

        if (props.externalLink) {
            setExternalLink!(props.externalLink);
        }
    });
    return null;
}
