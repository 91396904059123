import React from "react";
import styled from "styled-components";

import { Paragraph, Text } from "ui";
import {
    blue200,
    blue400,
    red100,
    red300,
    red400,
    yellow200,
    yellow500,
} from "ui/colors";
import { spacing25, spacing75 } from "ui/spacing";
import {
    IconCircleExclamationFilled,
    IconTriangleExclamationFilled,
} from "ui/icons";

type BannerType = "information" | "warning" | "error";

interface IBannerProps {
    /**
     * The type of banner to display e.g "information", "warning", "error".
     * @type BannerType
     */
    type: BannerType;

    /**
     * The title to display for the text content.
     * @type string
     */
    title: string;

    /**
     * The description to display for the text content.
     * @type string
     */
    description: React.ReactNode;

    /**
     * Action button should be any [**Button**](/docs/Button--docs) based component.
     * @type React.ReactElement
     */
    children?: React.ReactElement;
}

/**
 *  ```js
 * import { Banner } from "ui";
 * ```
 * The **Banner** component is for showcasing text content with an action button.
 */

const TYPE_VARIATIONS = {
    information: {
        backgroundColor: blue200,
        borderColor: blue400,
        icon: <IconCircleExclamationFilled color={blue400} />,
    },
    warning: {
        backgroundColor: yellow200,
        borderColor: yellow500,
        icon: <IconTriangleExclamationFilled color={yellow500} />,
    },
    error: {
        backgroundColor: red100,
        borderColor: red300,
        icon: <IconTriangleExclamationFilled color={red400} />,
    },
};

export default function Banner(props: IBannerProps) {
    return (
        <Root type={props.type}>
            <p>{TYPE_VARIATIONS[props.type]["icon"]}</p>
            <Paragraph>
                <Text bold>{props.title}</Text>
                <br />
                <Text>{props.description}</Text>
            </Paragraph>
            {props.children}
        </Root>
    );
}

const Root = styled.div<{ type: BannerType }>`
    display: flex;
    gap: ${spacing25};

    padding: ${spacing75};
    background: ${(props) => TYPE_VARIATIONS[props.type]["backgroundColor"]};
    border: 1px solid ${(props) => TYPE_VARIATIONS[props.type]["borderColor"]};
    border-radius: 4px;

    & > :nth-child(2) {
        flex: 1;
    }
`;
