import React from "react";
import styled from "styled-components";

import RootCard, { type ICardProps } from "./internal/RootCard";
import { yellow400 } from "ui/colors";
import { spacing125 } from "ui/spacing";

/**
 *  ```js
 * import { FeatureCard } from "ui";
 * ```
 *
 * The **FeatureCard** component is a general purpose component for highlighting
 * features and data on the marketing site.
 */
export default function FeatureCard(props: ICardProps) {
    return <Root>{props.children}</Root>;
}

const Root = styled(RootCard)`
    // Need to make this important because the class
    // specificity is equal and wasn't being applied.
    border-bottom: 8px solid ${yellow400} !important;

    padding: ${spacing125};
`;
