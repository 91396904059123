import React, { useState, useId } from "react";
import styled, { css } from "styled-components";

import { blue400, grey300, grey600, red100, red400 } from "ui/colors";
import { spacing100, spacing50, spacing75 } from "ui/spacing";

interface ISelectProps {
    /**
     * Array of options for the select, each option should be
     * an object with a `value` key set to a unique identifier,
     * like an `id` and a `label` key which will be shown in
     * the dropdown list.
     */
    options: Array<{ value: string; label: string }>;

    /**
     * Value of the selected option.
     */
    value: string | null;

    /**
     * Callback for a new item is selected, passes the option
     * of the selected options.
     */
    onValueChange: (value: string) => void;

    /**
     * Boolean to flag the error styling onto the Select.
     * @default false
     */
    error?: boolean;

    /**
     * Optional error message that will be shown below the Select.
     * If you're displaying the error message elsewhere nearby, there
     * is no need to add this here as well.
     */
    errorMessage?: string;

    /**
     * Children are used for the label of the Select. Supports
     * Text and Icon components only.
     * @type Text | Icon | Array<Text | Icon>
     */
    children?: React.ReactNode;

    /**
     * Optional ref that can be passed in. It will be attached to the
     * `<select>` DOM element, and can be used outside the component to focus
     * the select with the `focus()` function.
     */
    selectRef?: React.Ref<HTMLSelectElement>;
}

/**
 *  ```js
 * import { Select } from "ui";
 * ```
 *
 * The **Select** component should be used for selecting from a list of options.
 *
 * The `children` of this component represent the label for the Select, options should be
 * passed in using the `options` prop. The Select works by matching the value with the value
 * of the options to display the appropriate result.
 *
 * Your Select will also need to implement the `onValueChange` callback that passes the
 * selected option `value` back into the Select, as it a
 * [Controlled Component](https://react.dev/learn/sharing-state-between-components#controlled-and-uncontrolled-components):
 *
 * ```js
 * function ParentComponent() {
 *     const [value, setValue] = useState("");
 *
 *     return <Select
 *         options={[
 *             {value: "value1", label:"Label 1"},
 *             {value: "value2", label:"Label 2"}
 *         ]}
 *         value={value}
 *         onValueChange={setValue}
 *     >
 *         <Text>Select your value</Text>
 *     </Select>
 * }
 * ```
 */
export default function Select(props: ISelectProps) {
    const id = useId();
    const [focussed, setFocussed] = useState(false);

    function onChange(event: React.ChangeEvent<HTMLSelectElement>) {
        props.onValueChange(event.target.value);
    }
    function onFocus(event: React.FocusEvent<HTMLSelectElement>) {
        setFocussed(true);
    }
    function onBlur(event: React.FocusEvent<HTMLSelectElement>) {
        setFocussed(false);
    }

    return (
        <Root focussed={focussed}>
            <SelectEl
                id={id}
                ref={props.selectRef}
                value={props.value || ""}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                error={!!props.error}
            >
                {/* If there is nothing selected we need to show a blank
                    option at the top so it doesn't look like something 
                    is selected. 
                */}
                {!props.value && (
                    <option disabled selected value="">
                        &nbsp;
                    </option>
                )}
                {props.options.map(({ label, value }) => (
                    <option key={value} value={value}>
                        {label}
                    </option>
                ))}
            </SelectEl>
            {props.children && (
                <Label
                    htmlFor={id}
                    focussed={focussed}
                    hasValue={!!props.value}
                >
                    {props.children}
                </Label>
            )}
        </Root>
    );
}

const Root = styled.span<{ focussed: boolean }>`
    display: inline-block;
    width: 100%;
    position: relative;

    // Select arrow.
    &:after {
        position: absolute;
        right: 1em;
        top: 50%;

        border: 5px solid transparent;
        border-top-color: ${(props) => (props.focussed ? blue400 : grey600)};

        // Adjust the arrow to account for the top border
        transform: translateY(-2px);

        // Without this the element thinks it has no childrens
        // so doesn't render.
        content: "";
    }
`;

const SelectEl = styled.select<{ error: boolean }>`
    // Remove the border width to keep the height at a flat
    // 3em.
    padding: calc(${spacing75} - 2px);

    border: 2px solid;
    border-radius: 8px;
    border-color: ${(props) => (props.error ? red400 : grey300)};

    background-color: ${(props) => (props.error ? red100 : "white")};

    transition-property: border-color, background-color;
    transition-duration: 0.3s;

    &:focus {
        border-color: ${blue400};

        // Remove the default styling.
        outline: none;
    }
`;

const Label = styled.label<{
    focussed: boolean;
    hasValue: boolean;
}>`
    position: absolute;
    top: 0;
    left: 0;
    padding: ${spacing75};

    // Prevent click for the label so we can focus
    // the input underneath.
    pointer-events: none;

    color: ${grey300};

    transition-property: font-size, padding, transform, color, background-color;
    transition-duration: 0.3s;

    ${(props) =>
        props.hasValue &&
        css<any>`
            font-size: 0.75em;
            padding: 0 ${spacing50};
            transform: translateY(-50%) translateX(${spacing100});
            background-color: white;
            color: black;
        `}
`;
