import React from "react";
import RootCard, { type ICardProps } from "./internal/RootCard";

/**
 *  ```js
 * import { Card } from "ui";
 * ```
 *
 * The **Card** component is a general purpose component for containing
 * general data.
 */
export default function Card(props: ICardProps) {
    return <RootCard>{props.children}</RootCard>;
}
