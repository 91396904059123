import React, { useState, useId } from "react";
import styled from "styled-components";

import { grey300, blue400, red400 } from "ui/colors";
import { IconCheck } from "ui/icons";
import { spacing50, spacing75 } from "ui/spacing";

interface IToggleCheckboxProps {
    /**
     * Checked state of the checkbox.
     */
    checked: boolean;

    /**
     * Callback for when the checked state is changed.
     */
    onCheckedChange: (checked: boolean) => void;

    /**
     * Make the checkbox act like a block element
     * @default false
     */
    block?: boolean;

    /**
     * Disables the checkbox
     * @default false
     */
    disabled?: boolean;

    /**
     * Boolean to flag the error styling onto the checkbox.
     * @default false
     */
    error?: boolean;

    /**
     * Children are used for the label of the checkbox. Supports
     * `<Text>` and `<Icon>` components only.
     * @type Text | Icon | Array<Text | Icon>
     */
    children: React.ReactNode;
}

/**
 *  ```js
 * import { ToggleCheckbox } from "ui";
 * ```
 *
 * The **ToggleCheckbox** component should be used for boolean options (on/off). It functions
 * like a normal **Checkbox**, however it is styled to appear like a button.
 *
 * The `children` of this component represent the label for the ToggleCheckbox. Unlike other
 * interactive elements, **the children are required**. Also unlike other interactive elements
 * this component has a `checked` property rather than a `value` property to deal with user
 * input.
 *
 * Your ToggleCheckbox will also need to implement the `onCheckedChange` callback that passes the
 * updated `checked` property back into the ToggleCheckbox, as it a
 * [Controlled Component](https://react.dev/learn/sharing-state-between-components#controlled-and-uncontrolled-components):
 *
 * ```js
 * function ParentComponent() {
 *     const [checked, setChecked] = useState("");
 *
 *     return <ToggleCheckbox
 *         checked={checked}
 *         onValueChange={setChecked}
 *     >
 *         <Text>Active?</Text>
 *     </ToggleCheckbox>
 * }
 * ```
 */
export default function ToggleCheckbox(props: IToggleCheckboxProps) {
    const id = useId();
    const [focussed, setFocussed] = useState(false);

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        props.onCheckedChange(event.target.checked);
    }
    function onFocus(event: React.FocusEvent<HTMLInputElement>) {
        setFocussed(true);
    }
    function onBlur(event: React.FocusEvent<HTMLInputElement>) {
        setFocussed(false);
    }

    return (
        <Root
            block={!!props.block}
            disabled={!!props.disabled}
            focussed={focussed}
            error={!!props.error}
        >
            <span>{props.children}</span>
            <Checkbox
                type="checkbox"
                id={id}
                disabled={!!props.disabled}
                checked={!!props.checked}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            <CustomCheckbox>
                <IconCheck />
            </CustomCheckbox>
        </Root>
    );
}

const Root = styled.label<{
    block: boolean;
    disabled: boolean;
    focussed: boolean;
    error: boolean;
}>`
    // We want to keep this flexed, so that we can force the checkbox
    // to the right hand side when in block mode.
    display: ${(props) => (props.block ? "flex" : "inline-flex")};
    align-items: center;

    position: relative;
    width: ${(props) => (props.block ? "100%" : "auto")};

    // Remove the border width to keep the height at a flat
    // 3em.
    padding: calc(${spacing75} - 2px);

    // Make sure the checkbox and label appear on the same line
    white-space: nowrap;

    border: 2px solid;
    border-radius: 8px;
    border-color: ${(props) => {
        if (props.disabled) {
            return grey300;
        } else if (props.focussed) {
            return blue400;
        } else if (props.error) {
            return red400;
        } else {
            return grey300;
        }
    }};

    opacity: ${(props) => (props.disabled ? 0.3 : 1)};
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};

    transition-property: border-color, opacity;
    transition-duration: 0.3s;

    // Label Text - Force it to fill as much space as possible
    // so the checkbox is as far right as it can be.
    > :first-child {
        flex: 1;
    }
`;

const CustomCheckbox = styled.span`
    display: inline-block;
    width: 1.5em;
    height: 1.5em;

    margin-left: ${spacing50};

    border: 1px solid ${grey300};
    border-radius: 4px;

    color: ${blue400};
    text-align: center;

    > * {
        transform-origin: 50% 50%;
        transition-property: transform, opacity;
        transition-duration: 0.3s;
    }
`;

const Checkbox = styled.input<{ disabled: boolean }>`
    // Hide the checkbox out of the flow.
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;

    &:focus {
        // Remove default styles
        outline: none;
    }

    // Animate the check icon as the state changes.
    &:checked + ${CustomCheckbox} > * {
        opacity: 1;
        transform: scale(1);
    }
    &:not(:checked) + ${CustomCheckbox} > * {
        opacity: 0;
        transform: scale(0);
    }
`;
