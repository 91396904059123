import React, { createRef, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useRouter } from "next/router";
import { useSearchParams } from "next/navigation";

import SidebarContext from "./context";
import { SIDEBAR_NAV_WIDTH } from "./internal/button";

import { CONTEXT_MENU_ROOT_ID } from "ui/ContextMenu/ContextMenu";

// TODO: Change to sref, once we migrate standards to the
// new sidebar.
export const SIDEBAR_QUERYSTRING_KEY = "shref";

interface IProps {
    /**
     * Supports a single element, this will represent the content
     * except for the space needed for the sidebar
     * @type ReactElement<HTMLElement>
     */
    children: React.ReactElement<HTMLElement>;
}
export default function SidebarManager(props: IProps) {
    const router = useRouter();
    const searchParams = useSearchParams();
    const sidebarPortalRef = createRef<HTMLDivElement>();
    const [portalEl, setPortalEl] = useState<HTMLDivElement>();
    const [pinned, setPinned] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [opened, setOpened] = useState(
        searchParams.has(SIDEBAR_QUERYSTRING_KEY),
    );
    const [title, setTitle] = useState<string | undefined>(undefined);
    const [externalLink, setExternalLink] = useState<string | undefined>(
        undefined,
    );

    const [contentTop, setContentTop] = useState(0);
    const [contentWidth, setContentWidth] = useState(0);

    // Keep track of the root element so we can calculate the
    // width and top of the content area
    const rootRef = createRef<HTMLDivElement>();

    const closeSidebar = useCallback(() => {
        const { [SIDEBAR_QUERYSTRING_KEY]: _, ...query } = router.query;

        router.push(
            {
                query: {
                    ...query,
                },
            },
            undefined,
            // Don't scroll the page back to the top when the sidebar is closed,
            // this behaviour is annoying to everyone.
            { scroll: false },
        );

        // Always reset any expanded state when closing the sidebar.
        setExpanded(false);
    }, [router]);

    useEffect(() => {
        if (sidebarPortalRef.current) {
            setPortalEl(sidebarPortalRef.current);
        }
    }, [sidebarPortalRef]);

    useEffect(() => {
        function onClickOutside(event) {
            // Suppress events from modals that originated from the sidebar. This
            // currently happens in the portal, because org shared tables open a
            // modal. Otherwise we don't have this behaviour anywhere else (yet!)
            //
            // TODO - Refactor this to use a context from Modal, like we have for
            // portalEl here, when we convert the existing modals to the new UI Kit.
            // But for now, lets search the DOM for the root element of modals.
            const modalRootEl = document.getElementById("modalRoot");
            const contextMenuRootEl =
                document.getElementById(CONTEXT_MENU_ROOT_ID);
            if (
                !contextMenuRootEl?.contains(event.target) &&
                !modalRootEl?.contains(event.target) &&
                !portalEl?.contains(event.target) &&
                !pinned &&
                opened
            ) {
                closeSidebar();
            }
        }

        window.addEventListener("click", onClickOutside, true);
        return () => {
            window.removeEventListener("click", onClickOutside, true);
        };
    }, [portalEl, pinned, opened, closeSidebar]);

    useEffect(() => {
        setOpened(searchParams.has(SIDEBAR_QUERYSTRING_KEY));

        // Clean up the title and externalLink on change of the query string
        // in case the new page doesn't have either implemented.
        //
        // Doing this here to avoid render racing that was
        // happening when we were doing this in the SidebarContent
        // component.
        return () => {
            setTitle(undefined);
            setExternalLink(undefined);
        };
    }, [searchParams]);

    useEffect(() => {
        function computeContentSize() {
            if (rootRef.current) {
                const { width, top } = rootRef.current.getBoundingClientRect();
                const headerTop = 36; //px

                // This Math.min sticks the sidebar to the "top of the page", whether there is a page Header or not.
                // TODO: Find a better way that doesn't rely on hardcoding the Header height.
                setContentTop(window.scrollY + Math.min(top, headerTop));
                setContentWidth(width);
            }
        }

        window.addEventListener("resize", computeContentSize);
        computeContentSize();
        return () => {
            window.removeEventListener("resize", computeContentSize);
        };
    }, [rootRef]);

    return (
        <SidebarContext.Provider
            value={{
                portalEl,
                contentTop,
                contentWidth,
                opened,
                setOpened,
                pinned,
                setPinned,
                expanded,
                setExpanded,
                title,
                setTitle,
                currentUrl: router.query[SIDEBAR_QUERYSTRING_KEY] as string,
                closeSidebar,
                externalLink,
                setExternalLink,
            }}
        >
            <Root ref={rootRef}>
                {props.children}
                <SidebarPortalRoot
                    ref={sidebarPortalRef}
                    key="sidebarPortal"
                    opened={!!opened}
                    // TODO: Revisit this animation in the future, as when
                    // the sidebar is pinned the close animation looks like
                    // a transformer folding out.
                    pinned={!!pinned && !expanded}
                />
            </Root>
        </SidebarContext.Provider>
    );
}

const Root = styled.div`
    display: flex;
    position: relative;

    // Push the main content out to fill any remaining space.
    // If the sidebar is pinned, we'll make space for it.
    > :first-child {
        flex-grow: 1;
    }
`;

const SidebarPortalRoot = styled.div<{
    opened: boolean;
    pinned: boolean;
}>`
    position: relative;
    align-self: stretch;
    min-height: 100%;

    // Force the whole sidebar to be no smaller than 5em.
    // This will auto expand when the sidebar is pinned and
    // the content grows.
    flex-shrink: 0;
    min-width: ${SIDEBAR_NAV_WIDTH};

    // This will only ever be the sidebar root element.
    > :only-child {
        position: ${(props) =>
            props.opened && props.pinned ? "relative" : "absolute"};
        top: 0;
        right: 0;

        /**
         * HERE BE DRAGONS
         * 
         * So you came here to try and fix the header shadow overlapping the 
         * sidebar, did you? Before you change this to a bigger number:
         * 
         * When the page is scrolled the content box of the sidebar moves 
         * with the page even though this content remain in place because 
         * it's sticky. When sidebar is above the header to fix the shadow
         * the content box ends up overlapping to controls which makes them
         * unable to be interacted with.
         *
         * All combinations of overflows didn't help fix the problem, and
         * it wasn't worth fighting with this anymore. There is probably a
         * way to make this work, with some refactoring of the header, where
         * the shadow is detatched from the header and layered below the
         * sidebar, but until we clean up the header properly it's not worth
         * the effort.
         *
         * TODO - Revisit moving the shadow on the header, once we fully
         * clean it up. However this z-index will still remain in that
         * scenario.
         */
        z-index: 2;
    }
`;
