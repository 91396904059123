import React, { useContext } from "react";
import SidebarContext from "./context";

interface IProps {
    /**
     * Children HOC function that passes in the current
     * url of the sidebar.
     */
    children: (currentUrl?: string) => React.ReactElement<any>;
}

// This component exists so we can find it as a child
// of sidebar, and extract the content in to the sidebar
// nav.
export default function SidebarContent(props: IProps) {
    const { currentUrl } = useContext(SidebarContext);
    return props.children(currentUrl);
}
