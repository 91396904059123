import React, { ClipboardEvent, useState, useId } from "react";
import styled, { css } from "styled-components";
import { blue400, red100, red400, grey300, grey400, blue700 } from "ui/colors";

const INPUT_BORDER_RADIUS = "3px";

interface Props {
    value: string | null;
    onValueChange: (value: string) => void;
    children: React.ReactNode;
    error?: boolean;
    inputRef?: React.Ref<HTMLInputElement>;
    onBlur?: (ev: FocusEvent) => void;

    previewClass?: React.FunctionComponent<{ value: string | null }>;

    onPaste?: (event: ClipboardEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

function ClickToEditInput(props: Props) {
    const id = useId();
    const [isFocussed, setIsFocussed] = useState<boolean>(false);
    let showLabel = !!props.error || isFocussed || !props.value;

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        props.onValueChange(event.target.value);
    }

    const PreviewClass = props.previewClass;

    function onInputBlur(event) {
        setIsFocussed(false);
        if (props.onBlur) {
            props.onBlur(event);
        }
    }

    function onInputFocussed(event) {
        setIsFocussed(true);
        event.target.select();
    }

    return (
        <Root>
            {props.previewClass && (
                <PreviewWrapper
                    focussed={isFocussed}
                    error={!!props.error}
                    empty={!props.value}
                >
                    <props.previewClass value={props.value} />
                </PreviewWrapper>
            )}
            <Input
                id={id}
                type="text"
                value={props.value || ""}
                onChange={onChange}
                onFocus={onInputFocussed}
                onBlur={onInputBlur}
                offsetText={!!props.value && showLabel}
                error={!!props.error}
                ref={props.inputRef}
                onPaste={props.onPaste}
                disabled={!!props.disabled}
            />
            <Label
                htmlFor={id}
                isVisible={showLabel}
                inputHasValue={!!props.value}
                hasError={!!props.error}
            >
                {props.children}
            </Label>
        </Root>
    );
}
export default ClickToEditInput;

const Root = styled.span`
    display: block;
    width: 100%;
    position: relative;
`;

const Label = styled.label<{
    isVisible: boolean;
    inputHasValue: boolean;
    hasError: boolean;
}>`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 9px;

    border: 1px solid transparent;
    color: ${grey400};

    pointer-events: none;
    transition-property: font, padding, opacity, color;
    transition-duration: 0.3s;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};

    ${(props) =>
        props.inputHasValue &&
        css<any>`
            font-size: 0.75em;
            padding-top: 0;
            padding-bottom: 0;
            color: ${(props: { hasError: boolean }) =>
                !props.hasError ? blue400 : red400};
        `}
`;

const Input = styled.input<{ offsetText: boolean; error?: boolean }>`
    border: 1px solid;
    border-bottom-width: 3px;
    border-radius: ${INPUT_BORDER_RADIUS};
    border-color: transparent;
    padding: 0.75em;
    padding-bottom: calc(0.75em - 4px);
    transition-property: border-color, padding;
    transition-duration: 0.3s;

    &:hover:not([disabled]) {
        border-color: ${grey300};
    }

    &:focus {
        border-color: ${blue400};
        outline: none;
    }

    ${(props) =>
        props.error &&
        css`
            border-color: ${red400};
            background: ${red100};
        `}

    ${(props) =>
        props.offsetText &&
        css`
            padding-top: 1em;
            padding-bottom: calc(0.5em - 4px);
        `}
`;

const PreviewWrapper = styled.span<{
    focussed?: boolean;
    error: boolean;
    empty: boolean;
}>`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0.75em;

    // Hide the preview on error or empty input, it shows nothing useful.
    // We place the empty check here, instead of selectively rendering the component
    // so as to see the transitions
    opacity: ${(props) => (props.error || props.empty ? 0 : 1)};

    // Disable all the mouse event on the preview so the user,
    // can interact with the underlying input and all the hover/focus
    // events work as expected;
    pointer-events: none;

    // [1] Square off the bottom borders, so we can make it appear to be
    // an extension of the input itself. See [2] below.
    border-radius: 3px 3px 0 0;

    ${(props) =>
        props.focussed &&
        css`
            background-color: ${blue700};
            color: white;
            transform: translateY(-100%);
        `}

    transition-property: transform, background-color, opacity, color;
    transition-duration: 0.3s;

    // Hide the preview content if there is an error, so we don't see flashes
    // of "Error" show up in the preview box as it fades away.
    > * {
        visibility: ${(props) => (props.error ? "hidden" : "visible")};
    }

    // Minor CSS changes to the input itself to make the preview work
    & ~ ${Input} {
        // [2] Square up the top corners so the input sits flush with
        // with the dark blue preview box. See [1] above.
        ${(props) =>
            props.focussed &&
            css`
                border-radius: 0 0 ${INPUT_BORDER_RADIUS} ${INPUT_BORDER_RADIUS};
            `}

        // Hide the underlying input text (we still want the hover/error effect to show)
        // except when we're focussed (the preview box appears above) or the input
        // is erroring (the preview box is hidden).
        color: ${(props) =>
            props.focussed || props.error ? "revert" : "transparent"};
    }
`;
