import React from "react";
import styled from "styled-components";

import { Text } from "ui";
import { Color, grey300 } from "ui/colors";

interface IProps {
    /**
     * Display some text in the middle of the divider
     * @type <Text> elements only
     */
    children?: React.ReactNode;

    /**
     * Optional color to make the horizontal rule.
     * @default grey300
     */
    color?: Color;
}

export default function TextHorizontalRule(props: IProps) {
    return (
        <Root color={props.color}>
            <span>{props.children}</span>
        </Root>
    );
}

const Root = styled.div<{ color?: Color }>`
    display: flex;
    width: 100%;

    align-items: center;
    color: ${(props) => props.color || grey300};

    > span {
        padding: 1em;
    }

    &:before,
    &:after {
        border-bottom: 1px solid;
        flex: 1;
        height: 1px;
        content: "";
    }
`;
