import styled from "styled-components";

import Button from "../Button";
import { spacing75 } from "ui/spacing";

const UIButton = styled(Button)`
    padding: ${spacing75};
    border-radius: 8px;

    // We need to define this again for the
    // cases where we're using the buttons
    // as the root for the Primary/Secondary
    // button styled links.
    line-height: 1.5;

    transition: background-color 0.2s;
    text-align: center;
`;
export default UIButton;
