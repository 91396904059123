import React from "react";
import styled from "styled-components";

import RawLink, { type ICommonProps } from "./internal/RawLink";
import SecondaryButton from "ui/Button/SecondaryButton";

interface ISecondaryButtonLinkProps extends ICommonProps {
    /**
     * Force the button link to be a block element.
     * @default false
     */
    block?: boolean;
}

/**
 *  ```js
 * import { SecondaryButtonLink } from "ui";
 * ```
 *
 * The **SecondaryButtonLink** component represents a [**Link**](/docs/link-link--docs)
 * in the style of a [**SecondaryButtonLink**](/docs/buttons-secondarybutton--docs).
 * Because of this, they offer no customization, such as `inheritColor` or `supressHoverState`.
 *
 * They implement all the standard properties of a [**Link** (see Docs)](/docs/link-link--docs).
 */
export default function SecondaryButtonLink(props: ISecondaryButtonLinkProps) {
    return (
        <SecondaryButton as={StyledLink} {...props} block={!!props.block}>
            {props.children}
        </SecondaryButton>
    );
}

const StyledLink = styled(RawLink)<{ block: boolean }>`
    display: ${(props) => (props.block ? "block" : "inline-block")};
`;
