import React, { useState } from "react";
import styled from "styled-components";
import ContextMenuButton, {
    IContextMenuButtonProps,
} from "./ContextMenuButton";
import { spacing100 } from "ui/spacing";
import { grey500 } from "ui/colors";
import { IconCross } from "ui/icons";
import Text from "ui/Text/Text";

export interface IContextMenuConfirmProps {
    /**
     * Message to show when in the confirming state.
     */
    message: string;

    /**
     * Event callback for when the confirm button is clicked on.
     * @type
     */
    onConfirm: (event: React.MouseEvent) => void;

    /**
     * A function-as-children callback that provides `buttonProps` object
     * and `isConfirming` flag, and needs to return a
     * [**ContextMenuButton**](/docs/contextmenu-contextmenubutton--docs) component.
     *
     * @param buttonProps IContextMenuButtonProps object to pass directly to
     * <ContextMenuButton> child.
     * @param isConfirming Boolean of the current state of the confirm, helpful for
     * altering the content of the <ContextMenuButton> child, when in the confirming
     * state.
     * @type ({buttonProps: IContextMenuButtonProps, isConfirming: boolean}) => <ContextMenuButton>
     */
    children: (
        buttonProps: { onClick: (event) => void },
        isConfirming: boolean,
    ) => React.ReactElement<IContextMenuButtonProps>;
}

/**
 * ```js
 * import { ContextMenuConfirm } from "ui";
 * ```
 *
 * The **ContextMenuConfirm** is a button specifically for use inside the
 * [**ContextMenu**](/docs/contextmenu-contextmenu--docs) component. It provides an
 * interface to ask the user to confirm the action, for example delete, before
 * performing it.
 *
 * It wraps a singular [**ContextMenuButton**](/docs/contextmenu-contextmenubutton--docs)
 * in a function-as-children callback and uses it for both the initial button
 * and confirmation button, while managing all the internal transitions between
 * states.
 *
 * An optional `isConfirming` props is also passed through, for cases where you
 * might want to alter the state of the button when it's in its confirm button
 * state. For example, removing the text off the button to condense it down to
 * an icon only.
 *
 * **NOTE** It is strongly discouraged from setting props directly on the
 * [**ContextMenuButton**](/docs/contextmenu-contextmenubutton--docs), as it could
 * affect the `buttonProps` being passed in from working correctly. With that
 * said the `color` prop can still be used to alter the color of the button
 * in the confirming state.
 */
export default function ContextMenuConfirm(props: IContextMenuConfirmProps) {
    const [showConfirm, setShowConfirm] = useState(false);

    return (
        <Root>
            {showConfirm ? (
                <>
                    <Message onClick={(event) => event.preventDefault()}>
                        {props.message}
                    </Message>
                    <ControlsWrapper>
                        {props.children(
                            {
                                onClick: props.onConfirm,
                            },
                            true,
                        )}
                        <ContextMenuButton
                            onClick={(event) => {
                                event.preventDefault();
                                setShowConfirm(false);
                            }}
                        >
                            <IconCross />
                        </ContextMenuButton>
                    </ControlsWrapper>
                </>
            ) : (
                props.children(
                    {
                        onClick: (event) => {
                            event.preventDefault();
                            setShowConfirm(true);
                        },
                    },
                    false,
                )
            )}
        </Root>
    );
}

const Root = styled.div`
    display: flex;
    width: 100%;
`;
const Message = styled.p`
    padding: ${spacing100};
    flex: 1;

    // Don't allow the confirm message to wrap.
    //
    // When the menu is close to the right hand side of the screen,
    // it causes the messages to wrap, because we make the context
    // menu fit the window, so it's using the right edge as a boundary
    // when deciding to wrap.
    //
    // The wrapping then makes the positioning have a bad time, because
    // the width/right isn't easily computable because the content has
    // a dynamic width.
    white-space: nowrap;
`;
const ControlsWrapper = styled.div`
    display: flex;
`;
