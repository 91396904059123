// Icons (https://www.figma.com/file/9m8FYvxfOx0DsyzO2f2De6/ClearCalcs-Design-System-2022?node-id=7%3A17)
//
// If you add or remove icons to this file, please update the docs
// _docs/icons.mdx
import {
    FaAngleUp,
    FaAngleDown,
    FaAngleLeft,
    FaAngleRight,
    FaCaretDown,
    FaCaretRight,
    FaRegCheckCircle,
    FaRegTimesCircle,
    FaRegQuestionCircle,
    FaExclamationCircle,
    FaRegPlayCircle,
    FaRegPauseCircle,
    FaExclamationTriangle,
    FaPlus,
    FaArrowsAlt,
    FaQuestion,
    FaRegClone,
    FaRegFileAlt,
    FaFilter,
    FaRegFolder,
    FaBars,
    FaBuilding,
    FaRegHeart,
    FaSearch,
    FaRegTrashAlt,
    FaRegEnvelope,
    FaRegEye,
    FaLock,
    FaLink,
    FaExternalLinkAlt,
    FaRegClock,
    FaPaintBrush,
    FaPrint,
    FaRetweet,
    FaRegCreditCard,
    FaRegComments,
    FaRegCommentDots,
    FaGraduationCap,
    FaIndustry,
    FaRegUserCircle,
    FaRegUser,
    FaUsers,
    FaRegSmile,
    FaRegFrown,
    FaBold,
    FaItalic,
    FaRegFilePdf,
    FaRegFileExcel,
    FaFacebookSquare,
    FaTwitter,
    FaLinkedin,
    FaYoutube,
    FaCalculator,
    FaTools,
    FaGlasses,
    FaArrowRight,
    FaArrowLeft,
    FaChalkboardTeacher,
    FaBriefcase,
    FaPen,
    FaVideo,
    FaHandsHelping,
    FaRuler,
    FaHammer,
    FaHome,
    FaCheckCircle,
    FaGripVertical,
    FaShareAlt,
    FaListUl,
    FaBook,
    FaTimes,
    FaFont,
    FaTable,
    FaEllipsisV,
    FaCloudUploadAlt,
    FaClipboardCheck,
    FaBullhorn,
    FaStore,
    FaStoreAlt,
    FaLightbulb,
    FaRegCheckSquare,
    FaPercent,
    FaCodeBranch,
    FaCheck,
    FaRegPaperPlane,
    FaHouzz,
    FaCaretUp,
    FaMinus,
    FaExpand,
    FaCompress,
    FaGlobe,
    FaRedoAlt,
    FaCogs,
    FaInfoCircle,
    FaEdit,
    FaCog,
    FaArchive,
    FaUser,
    FaInfo,
    FaRocket,
    FaSpinner,
    FaPlay,
} from "react-icons/fa";
import {
    BsCloud,
    BsCloudArrowUp,
    BsCloudCheck,
    BsGrid3X3,
} from "react-icons/bs";
import {
    BiCool,
    BiFullscreen,
    BiExitFullscreen,
    BiSolidChevronsLeft,
    BiSolidChevronsRight,
    BiCollapseVertical,
    BiExpandVertical,
} from "react-icons/bi";
import { GrDocumentMissing } from "react-icons/gr";
import { RiDragDropLine, RiPushpin2Line, RiUnpinLine } from "react-icons/ri";
import { IoLibraryOutline } from "react-icons/io5";
import { VscTextSize } from "react-icons/vsc";
import { AiFillPicture } from "react-icons/ai";
import { MdInsertLink, MdOutlineSwapCalls } from "react-icons/md";
import { FaWandMagicSparkles } from "react-icons/fa6";

// Angles
export const IconAngleUp = IconBaselineAdjustment(FaAngleUp);
export const IconAngleDown = IconBaselineAdjustment(FaAngleDown);
export const IconAngleLeft = IconBaselineAdjustment(FaAngleLeft);
export const IconAngleRight = IconBaselineAdjustment(FaAngleRight);

// Arrows
export const IconArrowsRetweet = IconBaselineAdjustment(FaRetweet);
export const IconArrowsUpDownLeftRight = IconBaselineAdjustment(FaArrowsAlt);
export const IconArrowRight = IconBaselineAdjustment(FaArrowRight);
export const IconArrowLeft = IconBaselineAdjustment(FaArrowLeft);
export const IconRedo = IconBaselineAdjustment(FaRedoAlt);
export const IconSwap = IconBaselineAdjustment(MdOutlineSwapCalls);

// Calculator
export const IconCalculator = IconBaselineAdjustment(FaCalculator);
export const IconLibrary = IconBaselineAdjustment(IoLibraryOutline);
export const IconCross = IconBaselineAdjustment(FaTimes);
export const IconCheck = IconBaselineAdjustment(FaCheck);
export const IconPercent = IconBaselineAdjustment(FaPercent);
export const IconCodeBranch = IconBaselineAdjustment(FaCodeBranch);
export const IconRegCheckSquare = IconBaselineAdjustment(FaRegCheckSquare);
export const IconArchive = IconBaselineAdjustment(FaArchive);
export const IconGrid = IconBaselineAdjustment(BsGrid3X3);

// Carets
export const IconCaretUp = IconBaselineAdjustment(FaCaretUp);
export const IconCaretDown = IconBaselineAdjustment(FaCaretDown);
export const IconCaretRight = IconBaselineAdjustment(FaCaretRight);

// Circled Icons
export const IconCircleCheck = IconBaselineAdjustment(FaRegCheckCircle);
export const IconCheckCircle = IconBaselineAdjustment(FaCheckCircle);
export const IconCircleCross = IconBaselineAdjustment(FaRegTimesCircle);
export const IconCircleExclamationFilled =
    IconBaselineAdjustment(FaExclamationCircle);
export const IconCircleQuestion = IconBaselineAdjustment(FaRegQuestionCircle);
export const IconCirclePause = IconBaselineAdjustment(FaRegPauseCircle);
export const IconCirclePlay = IconBaselineAdjustment(FaRegPlayCircle);
export const IconCircleUser = IconBaselineAdjustment(FaRegUserCircle);
export const IconClock = IconBaselineAdjustment(FaRegClock);

// Cloud
export const IconCloudArrowUp = IconBaselineAdjustment(BsCloudArrowUp);
export const IconCloudCheck = IconBaselineAdjustment(BsCloudCheck);
export const IconCloudExclamation = IconBaselineAdjustment(BsCloud);
export const IconCloudUploadAlt = IconBaselineAdjustment(FaCloudUploadAlt);

export const IconCreditCard = IconBaselineAdjustment(FaRegCreditCard);
export const IconComments = IconBaselineAdjustment(FaRegComments);
export const IconCommentDots = IconBaselineAdjustment(FaRegCommentDots);
export const IconCopy = IconBaselineAdjustment(FaRegClone);
export const IconEnvelope = IconBaselineAdjustment(FaRegEnvelope);
export const IconEye = IconBaselineAdjustment(FaRegEye);
export const IconPaperPlane = IconBaselineAdjustment(FaRegPaperPlane);
export const IconRocket = IconBaselineAdjustment(FaRocket);

// Files
export const IconFileExcel = IconBaselineAdjustment(FaRegFileExcel);
export const IconFileLines = IconBaselineAdjustment(FaRegFileAlt);
export const IconFilePdf = IconBaselineAdjustment(FaRegFilePdf);
export const IconFileMissing = IconBaselineAdjustment(GrDocumentMissing);
export const IconFilePicture = IconBaselineAdjustment(AiFillPicture);

export const IconEdit = IconBaselineAdjustment(FaEdit);
export const IconFilter = IconBaselineAdjustment(FaFilter);
export const IconFolder = IconBaselineAdjustment(FaRegFolder);
export const IconGraduationCap = IconBaselineAdjustment(FaGraduationCap);
export const IconIndustry = IconBaselineAdjustment(FaIndustry);
export const IconInfo = IconBaselineAdjustment(FaInfo);
export const IconInfoCircle = IconBaselineAdjustment(FaInfoCircle);
export const IconExternalLinkAlt = IconBaselineAdjustment(FaExternalLinkAlt);
export const IconLink = IconBaselineAdjustment(FaLink);
export const IconLinkHorizontal = IconBaselineAdjustment(MdInsertLink);
export const IconLock = IconBaselineAdjustment(FaLock);
export const IconHeart = IconBaselineAdjustment(FaRegHeart);
export const IconMagnifyingGlass = IconBaselineAdjustment(FaSearch);
export const IconPaintBrush = IconBaselineAdjustment(FaPaintBrush);
export const IconPlus = IconBaselineAdjustment(FaPlus);
export const IconMinus = IconBaselineAdjustment(FaMinus);
export const IconPrint = IconBaselineAdjustment(FaPrint);
export const IconQuestion = IconBaselineAdjustment(FaQuestion);
export const IconQuestionCircle = IconBaselineAdjustment(FaRegQuestionCircle);
export const IconTrashCan = IconBaselineAdjustment(FaRegTrashAlt);
export const IconTriangleExclamationFilled = IconBaselineAdjustment(
    FaExclamationTriangle,
);
export const IconUser = IconBaselineAdjustment(FaRegUser);
export const IconUserFilled = IconBaselineAdjustment(FaUser);
export const IconUsers = IconBaselineAdjustment(FaUsers);
export const IconHome = IconBaselineAdjustment(FaHome);
export const IconBuilding = IconBaselineAdjustment(FaBuilding);
export const IconDragDrop = IconBaselineAdjustment(RiDragDropLine);
export const IconTableOfContents = IconBaselineAdjustment(FaListUl);
export const IconBook = IconBaselineAdjustment(FaBook);
export const IconTable = IconBaselineAdjustment(FaTable);
export const IconStore = IconBaselineAdjustment(FaStore);
export const IconStoreAlt = IconBaselineAdjustment(FaStoreAlt);
export const IconHouzz = IconBaselineAdjustment(FaHouzz);
export const IconWorld = IconBaselineAdjustment(FaGlobe);

// Faces
export const IconSmile = IconBaselineAdjustment(FaRegSmile);
export const IconFrown = IconBaselineAdjustment(FaRegFrown);
export const IconSunglasses = IconBaselineAdjustment(BiCool);
export const IconGlasses = IconBaselineAdjustment(FaGlasses);
export const IconWand = IconBaselineAdjustment(FaWandMagicSparkles);

// Grips
export const IconGripVertical = IconBaselineAdjustment(FaGripVertical);
export const IconContextMenu = IconBaselineAdjustment(FaEllipsisV);

// People
export const IconChalkboardTeacher =
    IconBaselineAdjustment(FaChalkboardTeacher);
export const IconHandsHelping = IconBaselineAdjustment(FaHandsHelping);

// Text Editor
export const IconBold = IconBaselineAdjustment(FaBold);
export const IconItalic = IconBaselineAdjustment(FaItalic);
export const IconFontSize = IconBaselineAdjustment(VscTextSize);
export const IconFont = IconBaselineAdjustment(FaFont);

// Tools
export const IconTools = IconBaselineAdjustment(FaTools);
export const IconBriefcase = IconBaselineAdjustment(FaBriefcase);
export const IconPen = IconBaselineAdjustment(FaPen);
export const IconRuler = IconBaselineAdjustment(FaRuler);
export const IconHammer = IconBaselineAdjustment(FaHammer);
export const IconClipboard = IconBaselineAdjustment(FaClipboardCheck);
export const IconBullhorn = IconBaselineAdjustment(FaBullhorn);
export const IconLightbulb = IconBaselineAdjustment(FaLightbulb);
export const IconFullscreen = IconBaselineAdjustment(FaExpand);
export const IconExitFullscreen = IconBaselineAdjustment(FaCompress);
export const IconBars = IconBaselineAdjustment(FaBars);
export const IconCogs = IconBaselineAdjustment(FaCogs);
export const IconCog = IconBaselineAdjustment(FaCog);
export const IconCollapseVertical = IconBaselineAdjustment(BiCollapseVertical);
export const IconExpandVertical = IconBaselineAdjustment(BiExpandVertical);

// Sidebar
export const IconPin = IconBaselineAdjustment(RiPushpin2Line);
export const IconUnpin = IconBaselineAdjustment(RiUnpinLine);
export const IconSidebarOpen = IconBaselineAdjustment(BiSolidChevronsLeft);
export const IconSidebarClose = IconBaselineAdjustment(BiSolidChevronsRight);

// Social
export const IconFacebook = IconBaselineAdjustment(FaFacebookSquare);
export const IconTwitter = IconBaselineAdjustment(FaTwitter);
export const IconLinkedin = IconBaselineAdjustment(FaLinkedin);
export const IconYoutube = IconBaselineAdjustment(FaYoutube);
export const IconVideo = IconBaselineAdjustment(FaVideo);
export const IconShare = IconBaselineAdjustment(FaShareAlt);

export const IconSpinner = IconBaselineAdjustment(FaSpinner);
export const IconPlay = IconBaselineAdjustment(FaPlay);

// All the icons in React Icon are off the baseline by a single pixel,
// there seems to be some discussion about this that is open, so I'm
// hopeful it'll be addressed properly.
//
// https://github.com/react-icons/react-icons/issues/222
//
// For now, rather than needing to deal with this everywhere, we'll
// contain the damage to just here.
//
// TODO - Remove this once this has been resolved upstream
function IconBaselineAdjustment(Icon) {
    function RebaselinedIcon(props) {
        return <Icon style={{ position: "relative", top: "1px" }} {...props} />;
    }
    RebaselinedIcon.displayName = Icon.name;

    return RebaselinedIcon;
}

// TODO: Understand why this isn't correct.
// export type Icon = ReturnType<typeof IconBaselineAdjustment>;
export type Icon = any;
