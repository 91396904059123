export default function isExternalUrl(url: string): boolean {
    try {
        new URL(url);
        return true;
    } catch (e) {
        if (e instanceof TypeError) {
            return false;
        }
        throw e;
    }
}
