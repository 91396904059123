import React from "react";
import katex from "katex";

import logUnhandledError from "framework/utils/logUnhandledError";

// Moved to index.tsx because of loading order issues.
// import "katex/dist/katex.min.css";

export function parseKatexString(math: string | number, katexOptions: {}) {
    // KaTeX doesn't safely support Unicode characters, as it doesn't have accurate metrics for them.
    // Replace them with the equivalent LaTeX commands to silence unknownSymbol warnings.
    math = `${math}`;
    math = math.replace(/\u2009/g, "\\,"); // thin space
    math = math.replace(/Δ/g, "\\Delta");
    math = math.replace(/≤/g, "\\leq");
    math = math.replace(/≥/g, "\\geq");
    math = math.replace(/⟂/g, "\\bot");
    math = math.replace(/ℓ/g, "\\ell");
    // The below replaces a string newline with the unicode character for newline
    // Due to the way we use KaTeX, this won't actually render as a newline, but it will render as
    // a symbol that indicates a newline (␤), which is at least much better than rendering as
    // "Error"!
    math = math.replace(/\\textbackslash{}n/g, " \\char9252 ");
    return katex.renderToString(math, katexOptions);
}

export function renderKatexString(
    math: string | number,
    katexOptions: {},
    equationErrorContext?: { context: string; details: {} },
) {
    try {
        return parseKatexString(math, katexOptions);
    } catch (e) {
        if (e instanceof Error || typeof e === "string") {
            equationErrorContext
                ? logUnhandledError(e, equationErrorContext)
                : logUnhandledError(e);
        }

        return "Error";
    }
}

type Props = {
    math: string | number;
    katexOptions?: {};
    // used to add context for rollbar logging. I.e. to differentiate between
    // standards equations erroring and all other errors.
    equationErrorContext?: { context: string; details: {} };
};

class MathDisplay extends React.Component<Props> {
    render() {
        if (this.props.math === "") return null;

        return (
            <span
                dangerouslySetInnerHTML={{
                    __html: renderKatexString(
                        this.props.math,
                        this.props.katexOptions || {},
                        this.props.equationErrorContext,
                    ),
                }}
            />
        );
    }

    shouldComponentUpdate(nextProps: Props) {
        return nextProps.math !== this.props.math;
    }
}
export default MathDisplay;
