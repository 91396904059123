import React, { useLayoutEffect, useRef, useState, useId } from "react";
import styled, { css } from "styled-components";

import { Paragraph } from "ui";
import { blue400, grey300, red100, red400 } from "ui/colors";
import { spacing100, spacing50, spacing75 } from "ui/spacing";
import { IconTriangleExclamationFilled } from "ui/icons";

interface ITextAreaProps {
    /**
     * Value for the content of the text area.
     */
    value: string | null;

    /**
     * Callback for when the value is changed.
     */
    onValueChange: (value: string) => void;

    /**
     * Allow the textarea to autogrow vertically to match the
     * content without a scrollbar.
     *
     * **Note** - This is one-directional and doesn't shrink the
     * textarea back down if the content is deleted.
     * @default false
     */
    autoGrow?: boolean;

    /**
     * Boolean to flag the error styling onto the textarea.
     * @default false
     */
    error?: boolean;

    /**
     * Optional error message that will be shown below the textarea.
     * If you're displaying the error message elsewhere nearby, there
     * is no need to add this here as well.
     */
    errorMessage?: string;

    /**
     * Children are used for the label of the textarea. Supports
     * Text and Icon components only.
     * @type Text | Icon | Array<Text | Icon>
     */
    children?: React.ReactNode;

    /**
     * Optional ref that can be passed in. It will be attached to the
     * textarea element for use outside the component to focus the
     * textarea.
     */
    textareaRef?: React.RefObject<HTMLTextAreaElement>;

    /**
     * Mark the textarea as required.
     * @default false
     */
    required?: boolean;
}

/**
 *  ```js
 * import { TextArea } from "ui";
 * ```
 *
 * The **TextArea** component should be used for open and multiline text input. Otherwise
 * an [**Input**](/docs/interactive-input--docs) component should be used.
 *
 * The `children` of this component represent the label for the TextArea, if you want to set
 * a value that can be edited, you should use the `value` prop.
 *
 * Your TextArea will also need to implement the `onValueChange` callback that passes the
 * updated `value` back into the TextArea, as it a
 * [Controlled Component](https://react.dev/learn/sharing-state-between-components#controlled-and-uncontrolled-components):
 *
 * ```js
 * function ParentComponent() {
 *     const [value, setValue] = useState("");
 *
 *     return <TextArea
 *         value={value}
 *         onValueChange={setValue}
 *     >
 *         <Text>Enter your value</Text>
 *     </TextArea>
 * }
 * ```
 */
export default function TextArea(props: ITextAreaProps) {
    const id = useId();
    const [focussed, setFocussed] = useState(false);

    // We need a ref for autoGrow, if a ref hasn't been passed in,
    // we can use this one instead.
    const textareaLocalRef = useRef<HTMLTextAreaElement>(null);

    useLayoutEffect(() => {
        // Use the passed in ref if we have it, or fallback to the
        // local one.
        const ref = props.textareaRef || textareaLocalRef;

        if (props.autoGrow && ref.current) {
            // HERE BE DRAGONS
            //
            // We need to get the computed value for the borders and apply it to the height
            // of the autogrow. We can't just assume 2px, because when the browser is zoomed
            // it may compute this to a fractional pixel width (Chrome 112, is known to have
            // this behaviour), this also means we need to parse it as a float.
            //
            // Without this, the autogrow may get into a state where the height doesn't match
            // a full pixel, and an infinite loop of trying to recompute the height happens.
            const borderBuffer =
                parseFloat(
                    window
                        .getComputedStyle(ref.current)
                        .getPropertyValue("border-top-width"),
                ) +
                parseFloat(
                    window
                        .getComputedStyle(ref.current)
                        .getPropertyValue("border-bottom-width"),
                );

            ref.current.style.height = `${ref.current.scrollHeight + borderBuffer}px`;
        }
    }, [props.autoGrow, props.value, props.textareaRef]);

    function onChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        props.onValueChange(event.target.value);
    }
    function onFocus(event: React.FocusEvent<HTMLTextAreaElement>) {
        setFocussed(true);
    }
    function onBlur(event: React.FocusEvent<HTMLTextAreaElement>) {
        setFocussed(false);
    }

    return (
        <Root>
            <TextAreaEl
                id={id}
                // Because the passed in ref is optional, we fallback
                // to a local one instead, as we need it to compute
                // heights if autoGrow is enable.
                ref={props.textareaRef || textareaLocalRef}
                value={props.value || ""}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                error={!!props.error}
                autoGrow={!!props.autoGrow}
                required={!!props.required}
            />
            {props.children && (
                <Label
                    htmlFor={id}
                    focussed={focussed}
                    hasValue={!!props.value}
                >
                    {props.children}
                </Label>
            )}
            {props.error && props.errorMessage && (
                <Paragraph color={red400}>
                    <IconTriangleExclamationFilled />
                    &nbsp;{props.errorMessage}
                </Paragraph>
            )}
        </Root>
    );
}

const Root = styled.span`
    display: block;
    width: 100%;
    position: relative;
`;

const TextAreaEl = styled.textarea<{ error: boolean; autoGrow: boolean }>`
    // Remove the border width to keep the height at a flat
    // 3em.
    padding: calc(${spacing75} - 2px);

    border: 2px solid;
    border-radius: 8px;
    border-color: ${(props) => (props.error ? red400 : grey300)};

    background-color: ${(props) => (props.error ? red100 : "white")};

    transition-property: border-color, background-color;
    transition-duration: 0.3s;

    // Only allow the textarea to be resized in the vertical
    // direction. If autogrow is enabled, we don't want to allow
    // the user to be able to resize the textarea as well.
    resize: ${(props) => (props.autoGrow ? "none" : "vertical")};

    &:focus {
        border-color: ${blue400};

        // Remove the default styling.
        outline: none;
    }
`;

const Label = styled.label<{
    focussed: boolean;
    hasValue: boolean;
}>`
    position: absolute;
    top: 0;
    left: 0;
    padding: ${spacing75};

    // Prevent click for the label so we can focus
    // the input underneath.
    pointer-events: none;

    color: ${grey300};

    transition-property: font-size, padding, transform, color, background-color;
    transition-duration: 0.3s;

    ${(props) =>
        (props.focussed || props.hasValue) &&
        css<any>`
            font-size: 0.75em;
            padding: 0 ${spacing50};
            transform: translateY(-50%) translateX(${spacing100});
            background-color: white;
            color: black;
        `}
`;
